import {request,request_op} from "../plugins/axios";

//获取用户信息
export function getUserInfo (params) {
    return request_op({
        url: '/openUserInfo/question',
        method: 'get',
        params: params
    })
}
//获取活动首页信息
export function getHomepageInfo (params) {
    return request({
        url: '/question/homepage',
        method: 'get',
        params: params
    })
}
//获取活动问题列表
export function getRecord (params) {
    return request({
        url: '/question/record',
        method: 'get',
        params: params
    })
}
//提交答案
export function postAnswer (params) {
    return request({
        url: '/question/answer',
        method: 'post',
        data: params
    })
}
//查询我的成绩
export function getMyScores (params) {
    return request({
        url: '/question/scores',
        method: 'get',
        params: params
    })
}
//查询答题详情
export function getAnswerDetail (params) {
    return request({
        url: '/question/answerDetail',
        method: 'get',
        params: params
    })
}
//抽奖奖品列表
export function getPrizeList (params) {
    return request({
        url: '/question/getPrize',
        method: 'get',
        params: params
    })
}
//领取奖品
export function getPreceivePrizes (params) {
    return request({
        url: '/question/receivePrizes',
        method: 'post',
        data: params
    })
}
// 领取奖品列表
export function getMyPrize (params) {
    return request({
        url: '/question/myPrize',
        method: 'get',
        params: params
    })
}
//现金红包领取
export function getredauthurl (params) {
    return request_op({
        url: '/openUserInfo/getQuesauthurl',
        method: 'get',
        params: params
    })
}
//查看是否已领奖
export function answerCashPrize (params) {
    return request({
        url: '/question/answerCashPrize',
        method: 'get',
        params: params
    })
}
