<template>
    <div class="page">
        <div class="search_box">
            <van-search v-model="value" @input="searchInput()" shape="round" placeholder="搜索支行"
                @clear="clearSerach()" />
        </div>
        <van-empty v-if="list.length==0" description="暂无数据" />
        <div v-else class="con">
            <div class="outlet_list" v-for="(item,index) in list" :key="index">
                <div class="list_top" @click="goDetail(item)">
                    <img :src="item.bankImage" alt="">
                    <div class="list_top_info">
                        <div class="title">{{item.name}}</div>
                        <div class="text">
                            <div class="text_top">
                                <img src="../../../static/wowebsite/loaction.png" alt="">
                                <span>{{item.farShow}}/{{item.address}}</span>
                            </div>
                            <div class="text_bottom">
                                <img src="../../../static/wowebsite/time.png" alt="">
                                <span>周一至周五:{{item.starttimes}}-{{item.endtimes}}{{item|weekReslt}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"></div>
                <div class="list_bottom">
                    <div class="label_left">
                        <div v-for="(item,index) in item.labelData" :key="index">
                            <div class="lebal_box" :style="{ background:item.color}">{{item.name}}</div>
                        </div>
                    </div>
                    <div class="label_right">
                        <div class="right_box" @click="openPhone(item)">
                            <img src="../../../static/wowebsite/lianxi@2x.png" alt="">
                            <span style="color: #3DA1FE;">联系Ta</span>
                        </div>
                        <div class="right_box" style="margin-left: 10px;" @click="goNav(item)">
                            <img src="../../../static/wowebsite/daohang-2@2x.png" alt="">
                            <span style="color: #7CD41D;">到这去</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 拨打电话弹窗 -->
        <van-dialog v-model="showPhoneCode" show-cancel-button cancelButtonText="否" @confirm="confirmTel"
            confirmButtonText="是" confirmButtonColor="#3974C6">
            <div class="phoneT">是否拨打电话？</div>
            <div style="text-align: center;margin-bottom: 20px;">{{callPhone}}</div>
        </van-dialog>
    </div>
</template>
<script>
    import { getSearchList, getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {

        },
        data() {
            return {
                value: '',
                list: [],
                labelData: [{
                    name: '距离最近',
                    color: '#50B6FF'
                }, {
                    name: '营业厅',
                    color: '#7CD41D'
                }],
                b: '',//跳转链接参数,
                callPhone: '',
                showPhoneCode: false,
            }
        },
        filters: {
            //处理周末时间展示
            weekReslt(item) {
                //位运算，将周末选项筛出
                let typeWeek = [1, 2];//周6 周日
                let weekend = []
                typeWeek.map((ele) => {
                    if ((item.weekend & ele) == ele) {
                        weekend.push(ele)
                    }
                })
                if (weekend.length == 2) {
                    return '周末:' + item.zmstartimes + '-' + item.zmendtimes
                } else if (weekend.length == 1 && weekend[0] == 1) {
                    return '周六:' + item.zmstartimes + '-' + item.zmendtimes
                } else if (weekend.length == 1 && weekend[0] == 2) {
                    return '周日:' + item.zmstartimes + '-' + item.zmendtimes
                }

            },

        },
        created() {
            // this.b = this.$route.query.b;
            // this.b =this.$store.state.wowebsiteB
            this.b = sessionStorage['wowebsiteFun']
            let params = {
                b: this.b,
                searchStr: ''
            }
            if (sessionStorage['userLoactionFun'] != undefined) {
                params.latitude = JSON.parse(sessionStorage['userLoactionFun']).latitude,
                    params.longitude = JSON.parse(sessionStorage['userLoactionFun']).longitude
            }
            this.getList(params)
            this.shareData()
        },
        mounted() {
        },
        methods: {
            //获取分享内容
            shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            searchInput(e) {
                let params = {
                    b: this.b,
                    searchStr: this.value
                }
                if (sessionStorage['userLoactionFun'] != undefined) {
                    params.latitude = JSON.parse(sessionStorage['userLoactionFun']).latitude,
                        params.longitude = JSON.parse(sessionStorage['userLoactionFun']).longitude
                }
                this.getList(params)
            },
            getList(params) {
                getSearchList(params).then((res) => {
                    if (res.code == 200) {
                        res.data.list.forEach(item => {
                            //位运算，将配置选项筛出
                            let typeData = [1, 2, 4, 8, 16]//1=免费停车 2=ATM 4=CRS 8=助农终端 16=便民服务
                            item.labelData = []
                            typeData.map((ele) => {
                                if ((item.servicesInfo & ele) == ele) {
                                    if (ele == 2) {
                                        item.labelData.push({ name: 'ATM', color: '#FD8927' })
                                    } else if (ele == 4) {
                                        item.labelData.push({ name: '营业厅', color: '#7CD41D' })
                                    }
                                }
                            })
                        });
                        this.list = res.data.list
                    }
                })
            },
            clearSerach() {
                // console.log("清除", this.value)
                this.value = ''
            },
            //前往支行详情
            goDetail(row) {
                let params = {
                    b: this.b,
                    bankId: row.id
                }
                if (sessionStorage['userLoactionFun'] != undefined) {
                    params.latitude = JSON.parse(sessionStorage['userLoactionFun']).latitude,
                        params.longitude = JSON.parse(sessionStorage['userLoactionFun']).longitude
                }
                this.$router.push({
                    path: "/wowebsite/branchDetail",
                    query: params,
                });
            },
            //打开内置地图
            goNav(row) {
                let openLocation = function () {
                    wx.openLocation({
                        latitude: parseFloat(row.latitude), // 纬度，浮点数，范围为90 ~ -90
                        longitude: parseFloat(row.longitude), // 经度，浮点数，范围为180 ~ -180。
                        name: row.name, // 位置名
                        address: row.address, // 地址详情说明
                        scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
                        //   infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
                    });

                };
                wxConfig(openLocation);
            },
            //拨打电话
            openPhone(row) {
                this.callPhone = row.mobile
                this.showPhoneCode = true;
            },
            //拨打电话
            confirmTel() {
                window.location.href = `tel:${this.callPhone}`;
            }

        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-search {
        background: #F9F9F9 !important;
        padding: 10px 0 !important
    }

    /deep/ .van-search__content {
        background-color: #FFFFFF
    }

    .page {
        background: #F9F9F9;
        /* padding: 0 19px 19px 19px; */
        height: 100%;

        .search_box {
            position: fixed;
            z-index: 999;
            top: 0px;
            width: 100%;
            padding: 0 19px 10px 19px;
        }

        .con {
            background: #F9F9F9;
            padding: 50px 19px 19px 19px;
        }

        .outlet_list {
            margin-top: 10px;
            width: 100%;
            background: #FFFFFF;
            border-radius: 5px;
            padding: 15px 15px 12px;

            .list_top {
                display: flex;
                flex-direction: row;

                img {
                    width: 62px;
                    height: 62px;
                }

                .list_top_info {
                    width: 75%;
                    margin-left: 15px;

                    .title {
                        font-size: 15px;
                        font-weight: bold;
                        color: #323232;
                    }

                    .text {
                        .text_top {
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            font-size: 10px;
                            font-weight: 400;
                            color: #969696;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            img {
                                width: 10px;
                                height: 12px;
                                margin-right: 4px;
                            }
                        }

                        .text_bottom {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            font-size: 10px;
                            font-weight: 400;
                            color: #969696;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }

            .line {
                margin-top: 8px;
                width: 100%;
                height: 1px;
                opacity: 0.37;
                background: #E8E8E8;
            }

            .list_bottom {
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .label_left {
                    :nth-child(n+2) {
                        margin-left: 5px;
                    }

                    display: flex;
                    flex-direction: row;

                    .lebal_box {
                        border-radius: 3px;
                        padding: 1px 8px;
                        font-size: 9px;
                        font-weight: 400;
                        color: #FFFFFF;
                        height: 15px;
                        line-height: 15px;
                        text-align: center;
                    }

                }

                .label_right {
                    display: flex;
                    flex-direction: row;

                    .right_box {
                        img {
                            width: 11px;
                            height: 11px;
                        }

                        span {
                            margin-left: 4px;
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    .phoneT {
        text-align: center;
        margin: 38px 38px 0 38px;
        height: 35px;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
    }
</style>