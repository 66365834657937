<template>
    <div class="page">
        <div class="box">
            <img style="width: 94px;height: 94px;" src="../../../static/wowebsite/chenggong@2x.png" alt="">
            <span class="title">{{serviceName}}预约成功</span>
            <span class="tips">请等待客户经理与您联系！</span>
            <van-button style="margin-top:35px" round type="default" @click="goHomepage()">回到首页</van-button>
            <van-button style="margin-top:16px" round type="info" @click="goDetail()">查看预约详情</van-button>
        </div>

    </div>
</template>

<script>
    import { getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {

        },
        data() {
            return {
                id:'',
                serviceName:'',
                b:''
            }
        },
        created() {
            this.id=this.$route.query.id
            this.b=sessionStorage['wowebsiteFun']
            this.serviceName=this.$route.query.serviceName
            this.shareData()
        },
        mounted() {
        },
        methods: {
              //获取分享内容
            shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            //回到首页
            goHomepage(){
                // let routeHistory = history.length - 1;
                // this.$router.go(-routeHistory);
                this.$router.replace(`/bank/index`)
            },
            goDetail(){
                this.$router.push({
                    path: '/wowebsite/mydetail',
                    query: {id:this.id},
                });
            }
            
        }
    }
</script>

<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        padding: 60px;
        height: 100%;

        .box {
            width: 100%;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;

            .title {
                margin-top: 22px;
                font-size: 16px;
                font-weight: bold;
                color: #262626;
            }

            .tips {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #717174;
            }

            img {
                margin: 0 auto;
            }
        }
    }
</style>