<template>
    <div class="page">
         <div v-html="content" v-if="content!=''"></div>
         <van-empty v-else description="暂无数据" />
    </div>
</template>
<script>
     import { getUserAgree,getbankShare } from '@/api/wowebsite'
     import { wxConfig } from "@/utils/wxCodeAuth";
     export default {
        components: {

        },
        data() {
            return {
                b: '',//跳转链接参数,
                content:""
            }
        },
        created() {
             // this.b = this.$route.query.b;
            // this.b =this.$store.state.wowebsiteB
            this.b=sessionStorage['wowebsiteFun']
            this.getData()
            this.shareData()
        },
        mounted() {
        },
        methods: {
            //获取分享内容
            shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            getData(){
                getUserAgree({b:this.b}).then((res)=>{
                    if(res.code==200){
                       this.content=res.data.content?res.data.content:''
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
  ::v-deep img {
        max-width: 100%;
    }
    ::v-deep video {
        max-width: 100%;
    }
 .page{
    padding: 15px;
 }
</style>