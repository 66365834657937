<template>
    <div class="page" id="canvasPoster" :style="{backgroundImage: 'url(' + certificateInfo.poster + ')', backgroundSize:'100% 100%'}">
        <div class="bomBox" v-if="posterImg==''">
            <div class="qRCode" ref="qrcode">
            </div>
            <div class="tips">我正在参加{{certificateInfo.corpName}}的{{showType}}活动,扫码即可挑战,快来领取奖品吧～</div>
            <div class="saveBtn"></div>
        </div>
        <div v-else class="bomBoxImg" style="text-align: center">
            <img :src="posterImg" alt="">
            <div class="saveBtn">长按保存图片</div>
        </div>
    </div>
</template>
<script>
    import html2canvas from "html2canvas";
    import QRCode from 'qrcodejs2'
    export default {
        data() {
            return {
                certificateInfo: {},
                posterImg: ''
            }
        },
        created() {
            this.certificateInfo = JSON.parse(localStorage.getItem('certificateInfo'))
            this.$nextTick(() => {
                this.crateCode()
            })
        },
        mounted() {},
        computed: {
            showType() {
                let typeMap = { 1: '问答考试', 2: '开奖问答', 3: '问卷' }
                return typeMap[this.certificateInfo.type]
            }
        },
        methods: {
            crateCode() {
                let redirectUrl = location.protocol + "//" + location.host + '/questions/index?id=' + this.$route.query.id
                new QRCode(this.$refs.qrcode, {
                    text: redirectUrl,
                    width: 130,
                    height: 130,
                    colorLight: '#fff',//二维码背景颜色
                    correctLevel: QRCode.CorrectLevel.L//容错率,L/M/H
                })
                //等待绘制好二维码后，创建海报
                this.createPoster();
            },
            // 创建海报
            createPoster() {
                const poster = document.getElementById("canvasPoster");
                html2canvas(poster, {
                    dpi: window.devicePixelRatio * 2,
                    // backgroundColor: "#fff",
                    useCORS: true,
                    scale: 2,
                    async: true,
                    allowTaint: true,
                    logging: true,
                    taintTest: false,
                    width: poster.offsetWidth,
                    height: poster.offsetHeight,
                }).then((canvas) => {
                    this.posterImg = canvas.toDataURL("image/jpeg");
                });
            },
        }
    }
</script>
<style lang="less" scoped>
    body {
        height: 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background: #F0F0F0;
        /* background-image: url("../../assets/images/questions/sheartPoster.png");
        background-size: 100% 100%; */
        .bomBox {
            position: absolute;
            bottom: 0;
            padding: 0px 60px;
            width: 100%;
            .qRCode {
                width: 147px;
                height: 147px;
                background: #FFFFFF;
                border-radius: 4px;
                padding: 12px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 139px;
                    height: 139px;
                }
            }
            .tips {
                margin-top: 15px;
                font-size: 17px;
                font-weight: 400;
                color: #FFFFFF;
                padding-bottom: 40px;
                text-align: center;
            }
            .saveBtn {
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 10px;
                padding-bottom: 20px;
            }
        }
        .bomBoxImg {
            /* width: 100%;
            height: 100%; */
            position: absolute;
            img {
                width: 100%;
                height: 100%;
            }
            .saveBtn {
                position: absolute;
                bottom: 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 24px;
                padding-bottom: 20px;
            }
        }
    }
</style>