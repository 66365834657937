<template>
    <div class="page">
         <div v-html="content"></div>
    </div>
</template>
<script>
     export default {
        components: {

        },
        data() {
            return {
                content:"<p>班克微联-手机端操作手册</p><p><img src=\"http://test.api.bankmartech.com/static/2022/1228/1944/1672227870675863ac2c1ea4fb7.png\" alt=\"\" data-href=\"\" style=\"\"/></p><p><img src=\"http://test.api.bankmartech.com/static/2022/1228/1944/1672227883181363ac2c2b2c442.png\" alt=\"\" data-href=\"\" style=\"\"/><img src=\"http://test.api.bankmartech.com/static/2022/1228/1944/1672227899246463ac2c3b3c285.png\" alt=\"\" data-href=\"\" style=\"\"/></p>"
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {

        }
    }
</script>
<style lang="less" scoped>
 ::v-deep img {
        max-width: 100%;
    }
    ::v-deep video {
        max-width: 100%;
    }
 .page{
    padding: 15px;
 }
</style>