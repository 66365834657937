<template>
    <div class="page">
        <img class="logo" :src="coverInfo.corp_logo" />
        <div class="title">{{coverInfo.corp_name}}</div>
        <div class="info">{{coverInfo.red_describe}}</div>
        <div class="amountBox">
            <span class="content">{{coverInfo.money}}</span>
            <span class="unit">元</span>
        </div>
        <div class="tips">
            <span class="tips_title">已存入零钱</span>
            <img class="icon" src="../../static/envelope/fanhui.png" />
        </div>
        <!-- 领取列表 -->
        <div class="receiveBox" v-if="detailList.length!=0">
            <div class="receiveTitle">领取{{detailList.length}}/{{coverInfo.red_number}}</div>
            <div class="line"></div>
            <div class="scorllBox">
                <div class="listBox" v-for="(item,index) in detailList" :key="index">
                    <div class="itemLeft">
                        <img class="icon" :src="item.avatar" />
                        <div class="leftInfo">
                            <div class="name">{{item.contactName}}</div>
                            <div class="time">{{item.createdAt}}</div>
                        </div>
                    </div>
                    <div class="itemRight">
                        <div class="money">{{item.money}}元</div>
                        <div class="best" v-if="item.king&&coverInfo.red_type!=3">
                            <img src="../../static/envelope/king.png" alt="">
                            <span>手气最佳</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import { GetRedInfo } from '@/api/redEnvelope'
    export default {
        data() {
            return {
                coverInfo: {},
                kingStatus: false,
                detailList: [
                    // { money: "0.19", contactName: "韧真", avatar: "", createdAt: "2023-05-08 18:02:16" },
                    // { money: "0.00", contactName: "Jase", avatar: "", createdAt: "2023-05-08 18:02:46" },
                    // { money: "0.19", contactName: "韧真", avatar: "", createdAt: "2023-05-08 18:02:16" },
                    // { money: "0.00", contactName: "Jase", avatar: "", createdAt: "2023-05-08 18:02:46" },
                ]
            }
        },
        created() {
            this.coverInfo = this.$route.query.coverInfo;
            if (this.$route.query.id) {
                this.getEnveInfo(this.$route.query.id)
            }

        },
        methods: {
            //获取红包信息
            getEnveInfo(id) {
                GetRedInfo({ id: id }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.red_number == res.data.childList.length) {
                            // //判断最大值添加手气最佳标识
                            let maxItem = Math.max.apply(null, res.data.childList.map(item => item.money))
                            this.detailList = res.data.childList.map(ele => {
                                if (ele.money == maxItem) {
                                    ele.king = true
                                }
                                return ele
                            });
                        } else {
                            this.detailList = res.data.childList
                        }
                    }
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    .page {
        width: 100%;
        height: 100vh;
        background-image: url("../../static/envelope/coverDetail.png");
        background-size: 100% 100%;
        display: flex;
        display: inline-block;

        .logo {
            margin-top: 156px;
            width: 69px;
            height: 69px;
            border-radius: 8px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }

        .title {
            font-size: 19px;
            font-weight: 400;
            color: #000000;
            text-align: center;
            margin-top: 12px;
        }

        .info {
            font-size: 15px;
            font-weight: 400;
            color: #9B9B9B;
            text-align: center;
            margin-top: 17px;
        }

        .amountBox {
            margin-top: 30px;
            text-align: center;

            .content {
                font-size: 62px;
                font-weight: 400;
                color: #C0A379;
            }

            .unit {
                font-size: 15px;
                font-weight: 400;
                color: #C0A379;
            }
        }

        .tips {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .tips_title {
                font-size: 15px;
                font-weight: 400;
                color: #C0A379;
            }

            .icon {
                margin-left: 5px;
                width: 6px;
                height: 10px;
            }

        }

        .receiveBox {
            margin-top: 35px;
            width: 100%;
            border-top: 7px solid #E4E4E4;
            padding: 15px;

            .receiveTitle {
                font-size: 14px;
                font-weight: 400;
                color: #BBBBBB;
            }

            .line {
                margin-top: 14px;
                width: 100%;
                height: 1px;
                background: rgba(6, 15, 26, 0.1);
            }

            .scorllBox {
                max-height: 400px;
                overflow: hidden;
                overflow-y: scroll;

                .listBox {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;

                    .itemLeft {
                        display: inline-flex;

                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 4px;
                        }

                        .leftInfo {
                            margin-left: 13px;

                            .name {
                                font-size: 15px;
                                font-weight: 400;
                                color: #000000;
                            }

                            .time {
                                font-size: 12px;
                                font-weight: 400;
                                color: #BBBBBB;
                            }
                        }
                    }

                    .itemRight {
                        .money {
                            font-size: 15px;
                            font-weight: 400;
                            color: #000000;
                            text-align: right;
                        }

                        .best {
                            img {
                                width: 15px;
                                height: 12px;
                            }

                            span {
                                margin-left: 5px;
                                font-size: 14px;
                                font-weight: 400;
                                color: #FFB41F;
                            }
                        }
                    }
                }

            }

        }

    }
</style>