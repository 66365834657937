<template>
    <div class="cardPage">
        <div class="cardBox">
            <div class="customName">客户经理{{qrcodeName}}为您服务</div>
            <div class="qrCode">
                <img :src="qrcodeUrl" alt="">
            </div>
            <div class="tips">长按添加我为好友！</div>
        </div>
        <img class="closeImg" src="../../../assets/images/questions/close.png" alt="" @click="closeFun">
    </div>
</template>
<script>
    export default {
        props: ['qrcodeUrl','qrcodeName'],
        data() {
            return {
            }
        },
        methods: {
            closeFun(){
                this.$emit('close', '');
            }
        }
    }
</script>
<style lang="less" scoped>
    .cardPage {
        padding: 0px 25px;
        .cardBox {
            margin-top: 100px;
            width: 100%;
            height: 444px;
            background-image: url("../../../assets/images/questions/cardBack.png");
            background-size: 100% 100%;
            .customName {
                padding-top: 57px;
                width: 100%;
                text-align: center;
                font-size: 20px;
                font-weight: 800;
                color: #52627D;
            }
            .qrCode {
                width: 192px;
                height: 192px;
                background-image: url("../../../assets/images/questions/codeBor.png");
                background-size: 100% 100%;
                margin: 0 auto;
                margin-top: 45px;
                padding: 12px 15px;
                img {
                    width: 163px;
                    height: 163px;
                }
            }
            .tips {
                margin-top: 40px;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                color: #52627D;
            }
        }
        .closeImg {
            margin-top: 20px;
            width: 32px;
            height: 32px;
        }
    }
</style>