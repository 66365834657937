<template>
    <div class="page" id="canvasPoster">
        <div v-if="posterImg==''">
        <div class="topBox"></div>
        <div class="botmBox">
            <div class="centerBox">
                <div class="logo">
                    <img :src="userInfo.headimgurl" alt="">
                    <div class="title">答题达人</div>
                </div>
                <div class="boxName">聪明机智，速度过人</div>
                <div class="qRCode" ref="qrcode">
                    <!-- <img src="../../assets/images/questions/certop.png" alt=""> -->
                </div>
                <div class="tips">我正在参加{{certificateInfo.corpName}}的{{showType}}活动,扫码即可挑战,快来领取奖品吧～</div>
                <div class="saveBtn"></div>
            </div>
        </div>
    </div>
    <div v-else class="bomBoxImg" style="text-align: center">
            <img :src="posterImg" alt="">
            <div class="saveBtn">长按保存图片</div>
        </div>
    </div>
</template>
<script>
    import html2canvas from "html2canvas";
    import QRCode from 'qrcodejs2'
    export default {
        data() {
            return {
                bgImageUrl: '../../assets/images/questions/certop.png',
                userInfo: {},
                certificateInfo: {},
                posterImg: ''
            }
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem('userInfoFun'))
            this.certificateInfo = JSON.parse(localStorage.getItem('certificateInfo'))
            this.$nextTick(() => {
                this.crateCode()
            })
        },
        computed: {
            showType() {
                let typeMap = { 1: '问答考试', 2: '开奖问答', 3: '问卷' }
                return typeMap[this.certificateInfo.type]
            }
        },
        mounted(){},
        methods: {
            crateCode() {
                let redirectUrl = location.protocol + "//" + location.host + '/questions/index?id=' + this.$route.query.id
                new QRCode(this.$refs.qrcode, {
                    text: redirectUrl,
                    width: 163,
                    height: 163,
                    colorLight: '#fff',//二维码背景颜色
                    correctLevel: QRCode.CorrectLevel.L//容错率,L/M/H
                })
                //等待绘制好二维码后，创建海报
                this.createPoster();
            },
            // 创建海报
            createPoster() {
                const poster = document.getElementById("canvasPoster");
                //由于内容高度大于页面高度，生成图片后按可视内容设置大小，所以生成的图片存在缩放。解决方法就是生成的图片大小按实际的画的。还存在一个问题，由于文案是固定在
                html2canvas(poster, {
                    dpi: window.devicePixelRatio * 2,
                    useCORS: true,
                    scale: 2,
                    async: true,
                    allowTaint: true,
                    logging: true,
                    taintTest: false,
                    width: poster.offsetWidth,
                    height: poster.offsetHeight,
                }).then((canvas) => {
                    this.posterImg = canvas.toDataURL("image/jpeg");
                });
            },
        }
    }
</script>
<style lang="less" scoped>
    body {
        height: 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background: #F0F0F0;
        .topBox {
            width: 100%;
            height: 235px;
            background-image: url("../../assets/images/questions/certop.png");
            background-size: cover;
            padding: 0 20px;
        }
        .botmBox{
            width: 100%;
            background: #F0F0F0;
            padding: 0 20px;
            .centerBox {
                position: relative;
                top: -50px;
                width: 100%;
                // height: 527px;
                background-image: url("../../assets/images/questions/cerbom.png");
                background-size: cover;
                padding: 0 36px;
                border-radius: 10px;
                .logo {
                    width: 104px;
                    height: 104px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    margin: 0 auto;
                    position: relative;
                    top: -50px;
                    overflow: hidden;
                    /* 裁剪超出部分 */
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        position: relative;
                        /* 将 img 元素设置为相对定位 */
                        z-index: 0;
                        /* 将 z-index 值设置为 0 */
                    }
                    .title {
                        position: absolute;
                        bottom: 0;
                        /* 调整 bottom 属性值 */
                        width: 100%;
                        height: 35px;
                        background: rgba(0, 0, 0, 0.56);
                        font-size: 15px;
                        font-weight: 400;
                        color: #FFFFFF;
                        text-align: center;
                        border-radius: 0px 0px 40px 40px;
                        line-height: 30px;
                        z-index: 1;
                    }
                }
                .boxName {
                    font-size: 17px;
                    font-weight: 600;
                    color: #FFBC29;
                    text-align: center;
                }
                .qRCode {
                    width: 196px;
                    height: 196px;
                    background-image: url("../../assets/images/questions/codeBor.png");
                    background-size: cover;
                    margin: 0 auto;
                    margin-top: 46px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 163px;
                        height: 163px;
                    }
                }
                .tips {
                    width: 100%;
                    margin-top: 40px;
                    font-size: 17px;
                    font-weight: 400;
                    color: #144384;
                    text-align: center;
                }
                .saveBtn {
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFBC29;
                    margin-top: 30px;
                    padding-bottom: 10px;
                }
            }
        }
        .bomBoxImg {
            /* width: 100%;
            height: 100%; */
            position: absolute;
            img {
                width: 100%;
                height: 100%;
            }
            .saveBtn {
                position: absolute;
                bottom: 0px;
                width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFBC29;
                    margin-top: 30px;
                    padding-bottom: 10px;
            }
        }
    }
</style>