<template>
    <div class="page">
        <div class="questBox">
            <div class="questName">在线问卷</div>
            <div class="fracBox">
                <div class="fracLeft">总共{{scoreData.answerRecord&&scoreData.answerRecord.length}}题，已答{{scoreData.answerNum}}题</div>
                <div class="fracRight" v-if="scoreData.showGrades==1">{{scoreData.score}}分</div>
            </div>
            <div class="questList">
                <div class="liBox" v-for="(item,index) in scoreData.answerRecord">
                    <div class="liName">Q{{index+1}}：{{item.question}}</div>
                    <div class="liImg" v-if="item.questionImage!=''">
                        <img :src="item.questionImage" alt="">
                    </div>
                    <!-- 单选||多选 -->
                    <div class="liRadio" v-if="item.type==1||item.type==2">
                        <div class="liRadioMargin"v-for="(val, key) in item.option" :key="key" :name="key" :class="{ tagCheck: isChosen(item.choose,key) }">
                            {{ key }}：{{ val }}
                        </div>
                    </div>
                    <!-- 单项填空-->
                    <div class="liRadio" v-if="item.type==3">
                        <van-field v-model="item.choose" readonly rows="2" autosize type="textarea" placeholder="请输入您的答案"
                            show-word-limit class="vanField" @blur="handleInputChange(item,item.id)" />
                    </div>
                    <!-- 问答 -->
                    <div class="liRadio" v-if="item.type==4">
                        <van-field v-model="item.choose" readonly rows="2" autosize type="textarea" placeholder="请输入您的答案"
                            show-word-limit class="vanField" @blur="handleInputChange(item,item.id)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { postAnswer,getAnswerDetail } from '@/api/questions'
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                radio: '',
                result: ['a', 'b'],
                message: '',
                questData: {},
                question: [],
                checkbox: {},
                postQuestion: [],//提交数据
                chosenOptions: [], // 存放已选中的选项
                scoreData: {}
            }
        },
        created() {
            this.getData()
        },
        methods: {
            async getData() {
                try {
                    const { code, data } = await getAnswerDetail({ id: this.$route.query.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid, answerId: this.$route.query.answerId })
                    if (code == 200) {
                        if (data.code === 0) {
                            this.scoreData = data.data
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {
                }
            },
            isChosen(choose,key) {
                return choose.includes(key);
            },
        },
        beforeDestroy() {
            // 清除定时器
            clearInterval(this.timer);
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-radio__label {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    /deep/ .van-checkbox__label {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    /deep/ .van-field__control {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    body {
        height: 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background: linear-gradient(97deg, #F9E2AC 0%, #DEB676 100%);
        padding: 35px 17px;
        .countdown {
            position: absolute;
            right: 17px;
            top: 6px;
            font-size: 16px;
            font-weight: bold;
            color: #B80000;
        }
        .questBox {
            width: 100%;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 0px 30px 50px;
            .questName {
                width: 146px;
                height: 34px;
                background: linear-gradient(180deg, #E7B77F 0%, #FCE8B4 100%);
                border-radius: 0px 16px 0px 16px;
                margin: 0 auto;
                position: relative;
                top: -15px;
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 34px;
                letter-spacing: 3px;
                text-align: center;
            }
            .fracBox{
                padding: 0px 0px 10px 0px;
display: flex;
justify-content:space-between;
flex-direction: row;
border-bottom: 1px solid #E2E2E2;
                .fracLeft{
                    font-size: 16px;
font-weight: 400;
color: #7F7F7F;
                }
                .fracRight{
                    font-size: 16px;
font-weight: 400;
color: #7F7F7F;
                }
            }
            .questList {
                width: 100%;
                margin-top: 20px;
                .liBox {
                    margin: 23px 0;
                    .liName {
                        font-size: 16px;
                        font-weight: 400;
                        color: #835100;
                        line-height: 23px;
                        letter-spacing: 1px;
                        text-decoration: underline;
                        vertical-align: text-bottom;
                        text-decoration-color: #FFF5DF;
                        text-decoration-thickness: 4px;
                    }
                    .liImg img {
                        margin: 17px 0 10px 0;
                        width: 135px;
                        height: 77px;
                    }
                    .liRadio {
                        margin-top: 10px;
                        .liRadioMargin {
                            margin-top: 10px;
                            margin-left: 10px;
                            font-size: 14px;
                            font-weight: 400;
                            color: #7F7F7F;
                        }
                        .tagCheck{
                            color: #DEB676;
                        }
                        .vanField {
                            background: #F1F1F1;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .submitBtn {
            width: 147px;
            height: 42px;
            background: #835100;
            border-radius: 22px;
            font-size: 20px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 42px;
            letter-spacing: 1px;
            text-align: center;
            margin: 0 auto;
            margin-top: 45px;
        }
    }
</style>