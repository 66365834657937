<template>
    <div class="page">
        <img class="topBackImg" src="../../assets/images/questions/recordBack.png" alt="">
        <div class="topBox">
            <div class="topBox2">
                <div class="topBox3">
                    <div class="round"></div>
                    <div class="centerbox">
                        <div class="title">中奖记录</div>
                        <div class="tips">记得及时兑奖哦～</div>
                    </div>
                    <div class="round"></div>
                </div>
            </div>
        </div>
        <div class="listBox" v-if="listData.length!=0">
            <div class="sideBox" v-for="(item,index) in listData" :key="index">
                <div class="time">{{item.createdAt}}</div>
                <div class="prizeBox">
                    <div class="leftBox">
                        <img :src="item.prize.image" alt="">
                        <div class="name">{{item.prize.name}}</div>
                    </div>
                    <div class="btn" @click="goDetail(item)">查看</div>
                </div>
            </div>
        </div>
        <no-data v-else />
        <!-- 中奖弹窗 -->
        <van-overlay :show="childShow">
            <prize-pop @close="childShow=false" ref="prizePop" :showType="showType" />
        </van-overlay>
    </div>
</template>
<script>
    import { getMyPrize } from '@/api/questions'
    import { Dialog } from 'vant';
    import prizePop from './components/prizePopup'
    import noData from './components/noData'
    export default {
        components: {
            prizePop,
            noData
        },
        data() {
            return {
                id: '',
                showType: 1,
                listData: [],
                childShow: false,
            }
        },
        created() {
            this.id = this.$route.query.id
            this.getData()
        },
        methods: {
            async getData() {
                try {
                    const { code, data, msg } = await getMyPrize({ id: this.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid });
                    if (code === 200) {
                        if (data.code === 0) {
                            this.listData = data.data
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {
                }
            },
            goDetail(item) {
                //1：客服二维码 2：兑换码 3：微信立减金 4：现场核销 5：现金红包
                this.showType = item.prize.cashPrizeType;
                if (item.prize.cashPrizeType == 2||item.prize.cashPrizeType == 4) {
                    this.$refs.prizePop.prizeInfoFun({cashPrizeType: item.prize.cashPrizeType,exchangeCode: item.exchangeCode });
                } else {
                    this.$refs.prizePop.prizeInfoFun(item.prize);
                }
                this.chieveShow = false;
                this.childShow = true;
            }
        }
    }
</script>
<style lang="less" scoped>
    body {
        height: 100%;
    }
    /deep/ .van-overlay {
        z-index: 199;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background: linear-gradient(137deg, #FF8326 0%, #FFB175 100%);
        padding: 18px 15px;
        .topBackImg {
            position: absolute;
            z-index: 18;
            left: 0;
            top: 0;
            width: 100%;
            height: 360px;
        }
        .topBox {
            position: relative;
            z-index: 19;
            width: 100%;
            height: 110px;
            background: linear-gradient(180deg, #FFF300 0%, #FFC300 53%, #FFC865 90%, #FFF77F 100%);
            box-shadow: 0px 5px 7px 0px rgba(179, 0, 0, 0.5), inset 0px 3px 2px 0px rgba(255, 255, 255, 0.5);
            border-radius: 12px;
            padding: 10px 12px;
        }
        .topBox2 {
            height: 100%;
            background: linear-gradient(180deg, #FFF300 0%, #FFC300 53%, #FFC865 90%, #FFF77F 100%);
            box-shadow: 0px 5px 7px 0px rgba(179, 0, 0, 0.5), inset 0px 3px 2px 0px rgba(255, 255, 255, 0.5);
            border-radius: 12px;
            padding: 10px 20px;
        }
        .topBox3 {
            width: 100%;
            height: 68px;
            background: #FF9100;
            box-shadow: inset 0px 6px 6px 0px rgba(255, 88, 0, 1);
            border-radius: 12px;
            border: 4px solid #FFC673;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 10px;
            .centerbox {
                text-align: center;
                .title {
                    font-size: 32px;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 48px;
                    letter-spacing: 5px;
                    text-shadow: 0px 1px 2px #FF0000;
                    line-height: 1;
                }
                .tips {
                    // margin-top: 4px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 20px;
                    letter-spacing: 2px;
                    text-shadow: 0px 1px 2px #FF0000;
                }
            }
            .round {
                width: 34px;
                height: 34px;
                background: linear-gradient(150deg, #FFF9B1 0%, #FFF2A5 27%, #FF8300 100%);
                box-shadow: 0px 5px 16px 0px rgba(255, 82, 0, 0.92), inset 0px 0px 5px 0px rgba(255, 255, 255, 0.5);
                border-radius: 50%;
            }
        }
        .listBox {
            position: relative;
            z-index: 19;
            width: 100%;
            background: #FFFFFF;
            border-radius: 11px;
            padding: 13px 26px;
            margin-top: 20px;
            overflow-y: scroll;
            .sideBox {
                margin: 13px 0px;
                .time {
                    font-size: 14px;
                    font-weight: 400;
                    color: #967B5E;
                }
                .prizeBox {
                    width: 100%;
                    background: #FFE5BE;
                    border-radius: 3px;
                    padding: 13px 10px;
                    margin-top: 10px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    .leftBox {
                        display: inline-flex;
                        img {
                            width: 46px;
                            height: 20px;
                        }
                        .name {
                            margin-left: 8px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6A4D3C;
                        }
                    }
                    .btn {
                        padding: 9px 19px;
                        background: linear-gradient(118deg, #FFD900 0%, #FF5C00 100%);
                        border-radius: 15px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
</style>