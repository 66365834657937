import {request,request_op} from "@/plugins/axios";

//获取用户信息基本信息
export function openUserInfoApi(params) {
    return request_op({
        url: '/openUserInfo/loan',
        method: 'get',
        params
    })
  }
  //获取账单信息
  export function getContactLoan(params) {
    return request({
      url: '/Loan/GetContactLoan',
      method: 'get',
      params: params
    })
  }