<template>
    <div class="page">
        <div class="flaot_box">
            <van-dropdown-menu active-color="#1989fa">
                <van-dropdown-item v-model="value1" title="预约状态" :options="option1" @change="dropdownBtn1" />
                <van-dropdown-item v-model="value2" title="排序" :options="option2" @change="dropdownBtn2" />
                <!-- <van-dropdown-item v-model="value3" :options="option3" /> -->
            </van-dropdown-menu>
        </div>
      
        <van-empty v-if="!pageLoading&&list.length==0" description="暂无数据" />
        <div class="content" v-else> 
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="list" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
                        <div class="head">
                            <span class="title">预约服务类型：{{item.serviceName}}</span>
                            <span class="stutas" v-if="item.status==0" style="color: #C0C0C0;">已取消</span>
                            <span class="stutas" v-if="item.status==1" style="color: #2A8FF7;">已提交</span>
                            <span class="stutas" v-if="item.status==2" style="color: #FF0000;">已完成</span>
                        </div>
                        <div class="line"></div>
                        <div class="cen">
                            <div>预约支行：{{item.bankName}}</div>
                            <div>预约时间：{{item.serviceTime}}</div>
                            <div>预约单号：{{item.orderNo}}</div>
                            <div class="line"></div>
                            <div class="bom">
                                客户经理：{{item.employeeName}}
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>

        </div>

    </div>
</template>
<script>
    import { record,getbankShare } from '@/api/wowebsite'
    export default {
        components: {

        },
        data() {
            return {
                pageLoading:true,
                value1: '',
                value2: 0,
                value3: 'a',
                option1: [
                    { text: '全部', value: '' },
                    { text: '已取消', value: 0 },
                    { text: '已提交', value: 1 },
                    { text: '已完成', value: 2 },
                ],
                option2: [
                    { text: '从新到旧', value: 1 },
                    { text: '从旧到新', value: 2 },
                ],
                // option3: [
                //     { text: '默认排序', value: 'a' },
                //     { text: '好评排序', value: 'b' },
                //     { text: '销量排序', value: 'c' },
                // ],
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                b: '',//跳转链接参数
                pageParams: {
                    page: 1,
                    perPage: 10
                }
            }
        },
        created() {
            // this.b = this.$route.query.b;
            // let params = {
            //     b: this.b,
            //     union_id: 'o7W6Z5uUfEJ81Adevo_-5xlFL1eU',
            // }
            // this.getData(params)
            // this.b =this.$store.state.wowebsiteB
            this.b=sessionStorage['wowebsiteFun']
            this.shareData()
            // this.onLoad()
        },
        mounted() {
        },
        methods: {
             //获取分享内容
             shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            onLoad() {
                let params = {
                    ...this.pageParams,
                    b: this.b,
                    union_id: JSON.parse(sessionStorage['userInfoFun']).unionid,
                }
                this.getData(params)
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.pageParams = {
                    page: 1,
                    perPage: 10
                }
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            getData(params) {
                record(params).then((res) => {
                    if (res.code == 200) {
                        setTimeout(() => {
                            this.pageLoading=false
                            this.pageParams.page++
                            if (this.refreshing) {
                                this.list = [];
                                this.refreshing = false;
                            }
                            this.list.push(...res.data.list)
                            this.loading = false;
                            if (this.list.length >= res.data.page.total) {
                                this.finished = true;
                                return
                            }
                        }, 1000);
                    }
                })
            },
            //前往详情
            goDetail(row) {
                this.$router.push({
                    path: '/wowebsite/mydetail',
                    query: { id: row.id },
                });
            },
            //下拉1
            dropdownBtn1(val) {
                this.resuleChoose()
                let params = {
                    ...this.pageParams,
                    b: this.b,
                    union_id: JSON.parse(sessionStorage['userInfoFun']).unionid,
                    status: val
                }
                this.getData(params)
            },
            //下拉2
            dropdownBtn2(val) {
                this.resuleChoose()
                let params = {
                    ...this.pageParams,
                    b: this.b,
                    union_id: JSON.parse(sessionStorage['userInfoFun']).unionid,
                    order: val
                }
                this.getData(params)
            },
            //重置选择
            resuleChoose(){
                this.pageLoading=true
                this.loading = true;
                this.finished = false;
                this.pageParams = {
                    page: 1,
                    perPage: 10
                }
                this.list = [];
            }
        }
    }
</script>
<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        height: 100%;

        .flaot_box {
            position: fixed;
            z-index: 999;
            top: 0px;
            width: 100%;
        }

        .content {
            background: #F9F9F9;
            /* height: 100%; */
            padding: 60px 20px 20px 20px;

            .list {
                background: #FFFFFF;
                border-radius: 7px;

                /* padding: 11px 14px; */
                &:nth-child(n+2) {
                    margin-top: 10px;
                }

                .head {
                    display: flex;
                    justify-content: space-between;
                    padding: 11px 14px 0 14px;

                    .title {
                        font-size: 15px;
                        font-weight: bold;
                        color: #000000;
                    }

                    .stutas {
                        font-size: 15px;
                        font-weight: 400;
                        color: #2A8FF7;
                    }
                }

                .line {
                    margin-top: 11px;
                    width: 100%;
                    height: 1px;
                    opacity: 0.4;
                    background: #E8E8E8;
                }

                .cen {
                    padding: 12px 14px 12px 14px;


                    div {
                        &:nth-child(n+2) {
                            margin-top: 5px;
                        }

                        font-size: 15px;
                        font-weight: 400;
                        color: #717174;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .line {
                        margin-top: 17px !important;
                    }

                    .bom {
                        margin-top: 10px !important;
                        font-size: 14px;
                        font-weight: 400;
                        color: #717174;
                    }
                }
            }
        }
    }
</style>