<template>
    <div class="nairePage">
        <page-load v-if="pageLoading" />
        <div class="countdown" v-if="questData.duration!=0">{{timeString}}</div>
        <div class="questBox">
            <div class="questName">在线问卷</div>
            <div class="questList">
                <div class="liBox" v-for="(item,index) in questData.question">
                    <div class="liName">Q{{index+1}}：{{item.question}}</div>
                    <div class="liImg" v-if="item.questionImage!=''">
                        <img :src="item.questionImage" alt="">
                    </div>
                    <!-- 单选 -->
                    <div class="liRadio" v-if="item.type==1">
                        <van-radio-group v-model="radiobox[index]" @change="handleRadioChange(item,index)">
                            <van-radio icon-size="16px" checked-color="#7ED321" class="liRadioMargin"
                                v-for="(val, key) in item.option" :key="key" :name="key">
                                {{ key }}：{{ val }}
                            </van-radio>
                        </van-radio-group>
                    </div>
                    <!-- 多选 -->
                    <div class="liRadio" v-if="item.type==2">
                        <van-checkbox-group v-model="checkbox[index]" @change="handleCheckboxChange(item,index)">
                            <van-checkbox icon-size="16px" checked-color="#7ED321" class="liRadioMargin"
                                v-for="(val, key) in item.option" :key="key" :name="key" :value="key">
                                {{ key }}：{{ val }}
                            </van-checkbox>
                        </van-checkbox-group>
                    </div>
                    <!-- 单项填空-->
                    <div class="liRadio" v-if="item.type==3">
                        <van-field v-model="item.message" rows="2" autosize type="textarea" placeholder="请输入您的答案"
                            show-word-limit class="vanField" @blur="handleInputChange(item,item.id)" />
                    </div>
                    <!-- 问答 -->
                    <div class="liRadio" v-if="item.type==4">
                        <van-field v-model="item.message" rows="2" autosize type="textarea" placeholder="请输入您的答案"
                            show-word-limit class="vanField" @blur="handleInputChange(item,item.id)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="submitBtn" @click="submitBtn">提交</div>
        <!-- 提交load -->
        <van-overlay :show="overlayShow" @click="overlayShow = false">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <van-loading size="24px" vertical color="#1989fa">提交中...</van-loading>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
    import { postAnswer } from '@/api/questions'
    import pageLoad from '../components/pageLoad'
    import throttle from '../utils/throttle';
    import { Dialog } from 'vant';
    export default {
        components: {
            pageLoad,
        },
        data() {
            return {
                overlayShow: false,
                radio: '',
                result: ['a', 'b'],
                message: '',
                questData: {},
                question: [],
                radiobox: {},
                checkbox: {},
                pageLoading: false,
                postQuestion: [],//提交数据
                chosenOptions: [], // 存放已选中的选项\\
            }
        },
        created() {
            this.questData = this.$route.query
            this.pageLoading = true
            setTimeout(() => {
                this.pageLoading = false;
            }, 1000)
            // 每秒更新剩余时间
            if (this.questData.duration != '0') {
                this.timer = setInterval(() => {
                    if (this.questData.duration > 0) {
                        this.questData.duration--;
                    } else {
                        this.postSubmit()
                        clearInterval(this.timer);
                    }
                }, 1000);
            }
        },
        computed: {
            timeString() {
                // 计算剩余时间的字符串表示
                let minutes = Math.floor(this.questData.duration / 60);
                let seconds = this.questData.duration % 60;
                return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            }
        },
        methods: {
            // 单选框选项触发事件
            handleRadioChange(item, index) {
                const chosenOption = Object.keys(this.questData.question[index].option).find((key) => this.radiobox[index].includes(key));
                const json = { id: item.id, choose: [chosenOption], text: this.message };
                this.updatePostQuestion(json);
            },
            // 多选框选项事件
            handleCheckboxChange(item, index) {
                const checkedKeys = Object.keys(item.option).filter((key) => this.checkbox[index].includes(key));
                const json = { id: item.id, choose: checkedKeys, text: this.message };
                this.updatePostQuestion(json);
            },
            // 填空题选项事件
            handleInputChange(item, questionId) {
                const json = { id: item.id, choose: [], text: item.message };
                this.updatePostQuestion(json);
            },
            // 更新postQuestion数组，并去重
            updatePostQuestion(json) {
                const index = this.postQuestion.findIndex((q) => q.id === json.id);
                if (index === -1) {
                    // 如果postQuestion数组中不存在这个问题，则直接添加新的问题
                    this.postQuestion.push(json);
                } else {
                    // 如果postQuestion数组中已经存在这个问题，则替换旧的问题
                    this.postQuestion.splice(index, 1, json);
                }
            },
            submitBtn: throttle(async function () {
                //倒计时提交的时候判断提交数组this.postQuestion跟this.questData.question比较
                //假设this.questData.question中不存在this.postQuestion中相同的id数据，
                //则把this.questData.question按格式 let json = { id: this.questData.question[xx].id, choose: [], text: '' }; push到this.postQuestion数组中
                this.overlayShow = true
                let newQuestions = this.questData.question.filter((question) => {
                    return !this.postQuestion.some((postQuestion) => postQuestion.id === question.id);
                });
                if (newQuestions.length != 0) {
                    newQuestions.forEach(ele => {
                        let json = { id: ele.id, choose: [], text: '' };
                        this.postQuestion.push(json)
                    });
                }
                try {
                    const { code, data, msg } = await postAnswer({ id: this.$route.query.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid, question: this.postQuestion });
                    if (code === 200) {
                        if (data.code === 0) {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/questions/achievement?id=' + this.$route.query.id,
                                    query: {
                                        scoreData:JSON.stringify(data.data)
                                    },
                                });
                            }, 2000)
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {
                    console.log('error',error)
                } finally {
                    setTimeout(() => {
                        this.overlayShow = false
                    }, 2000)
                }
            }, 1500),
            //时间到期，自动提交
            postSubmit() {
                Dialog.alert({ message: '已到答题规定时间，系统自动提交答题！', confirmButtonColor: '#267EF0' });
                this.submitBtn()
            }
        },
        beforeDestroy() {
            // 清除定时器
            clearInterval(this.timer);
        },
    }
</script>
<style lang="less" scoped>
    /deep/ .van-radio__label {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    /deep/ .van-checkbox__label {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    /deep/ .van-field__control {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    body {
        height: 100%;
    }
    .nairePage {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background: linear-gradient(97deg, #F9E2AC 0%, #DEB676 100%);
        padding: 35px 17px;
        .countdown {
            position: absolute;
            right: 17px;
            top: 6px;
            font-size: 16px;
            font-weight: bold;
            color: #B80000;
        }
        .questBox {
            width: 100%;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 0px 30px 50px;
            .questName {
                width: 146px;
                height: 34px;
                background: linear-gradient(180deg, #E7B77F 0%, #FCE8B4 100%);
                border-radius: 0px 16px 0px 16px;
                margin: 0 auto;
                position: relative;
                top: -15px;
                font-size: 20px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 34px;
                letter-spacing: 3px;
                text-align: center;
            }
            .questList {
                width: 100%;
                margin-top: 20px;
                .liBox {
                    margin: 23px 0;
                    .liName {
                        font-size: 16px;
                        font-weight: 400;
                        color: #835100;
                        line-height: 23px;
                        letter-spacing: 1px;
                        text-decoration: underline;
                        vertical-align: text-bottom;
                        text-decoration-color: #FFF5DF;
                        text-decoration-thickness: 4px;
                    }
                    .liImg img {
                        margin: 17px 0 10px 0;
                        width: 135px;
                        height: 77px;
                    }
                    .liRadio {
                        margin-top: 10px;
                        .liRadioMargin {
                            margin-top: 10px;
                        }
                        .vanField {
                            background: #F1F1F1;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
        .submitBtn {
            width: 147px;
            height: 42px;
            background: #835100;
            border-radius: 22px;
            font-size: 20px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 42px;
            letter-spacing: 1px;
            text-align: center;
            margin: 0 auto;
            margin-top: 45px;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 200px;
    }
</style>