<template>
  <div class="page">
    <div class="loadBox" v-if="pageLoading">
      <van-loading size="24px" color="#0094ff" vertical>加载数据中...</van-loading>
    </div>
    <div v-else>
      <van-search v-model="value" @focus="goSearch()" shape="round" placeholder="搜索支行" />
      <div class="banner" v-if='homeData.bankBannerImg&&homeData.bankBannerImg.length!=0'>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <div v-for="(item,index) in homeData.bankBannerImg" :key="index">
            <van-swipe-item>
              <img style="width: 100%;height: 150px;border-radius:18px" :src="item.bannerImage" @click="bannerBtn(item)"
                alt="">
            </van-swipe-item>
          </div>
        </van-swipe>
      </div>
      <!-- 功能选项  -->
      <div class="tab" v-if='homeData.bankBannerBtn&&homeData.bankBannerBtn.length!=0'
        :style="homeData.bankBannerBtn.length<=4?'flex-wrap: wrap;':homeData.bankBannerBtn.length>8?'flex-flow: column wrap;height:155px':'flex-wrap: wrap;'">
        <div class="tab_box" v-for="(item,index) in homeData.bankBannerBtn" :key="index" @click="goPage(item)">
          <img :src="item.bannerImage" alt="">
          <span>{{item.name}}</span>
        </div>
      </div>
      <!-- 通告 -->
      <div class="notice" @click="skipToutiao">
        <img src="../../../static/wowebsite/notice.png" alt="">
        <span v-if="noticeData.length!=0">{{ noticeData[0].title }}</span>
        <span v-else>暂无通知！</span>
      </div>
      <!-- 热推产品 -->
      <div class="product">
        <div class="title">热推产品</div>
        <div class="product_tab" v-if="options.length!=0">
          <div class="product_list" v-for="(item,index) in options" :key="index">
            <div class="product_name" :class="{ active: index == choose }" @click="selectTab(item,index)">{{item.name}}
            </div>
          </div>
        </div>
        <div v-if="productData.length!=0">
          <div class="product_con" v-for="(item,index) in productData" :key="index" @click="skipProductDetail(item.id)">
            <div class="product_con_left">
              <div class="title">{{item.name}}</div>
              <div class="content">{{item.summary}}</div>
            </div>
            <div class="product_con_right">
              <img :src="item.fullImage" alt="">
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
      <!-- 精选活动 -->
      <div class="huDong" v-if="activityData!=''">
        <div class="huDong_title">
          <div class="title">精选活动</div>
          <div class="more" @click="skipMoreAct">查看更多</div>
        </div>
        <img :src="activityData.fullImage" @click="skipActDetail" alt="">
      </div>
      <!--附近网点  -->
      <div class="outlet">
        <div class="title">附近网点</div>
        <div v-if="homeData.list&&homeData.list.length!=0">
          <div class="outlet_list" v-for="(item,index) in homeData.list" :key="index">
            <div class="list_top" @click="goDetail(item)">
              <img :src="item.bankImage" alt="">
              <div class="list_top_info">
                <div class="title">{{item.name}}</div>
                <div class="text">
                  <div class="text_top">
                    <img src="../../../static/wowebsite/loaction.png" alt="">
                    <span>{{item.farShow}}/{{item.address}}</span>
                  </div>
                  <div class="text_bottom">
                    <img src="../../../static/wowebsite/time.png" alt="">
                    <span>周一至周五:{{item.starttimes}}-{{item.endtimes}}{{item|weekReslt}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="list_bottom">
              <div class="label_left">
                <div v-for="(item,index) in item.labelData" :key="index">
                  <div class="lebal_box" :style="{ background:item.color}">{{item.name}}</div>
                </div>
              </div>
              <div class="label_right">
                <div class="right_box">
                  <img src="../../../static/wowebsite/lianxi@2x.png" alt="" @click="openPhone(item)">
                  <span style="color: #3DA1FE;">联系Ta</span>
                </div>
                <div class="right_box" style="margin-left: 10px;" @click="goNav(item)">
                  <img src="../../../static/wowebsite/daohang-2@2x.png" alt="">
                  <span style="color: #7CD41D;">到这去</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据" />
        </div>
      </div>
      <div style="width:100%;height:80px;"></div>
      <!-- 拨打电话弹窗 -->
      <van-dialog v-model="showPhoneCode" show-cancel-button cancelButtonText="否" @confirm="confirmTel"
        confirmButtonText="是" confirmButtonColor="#3974C6">
        <div class="phoneT">是否拨打电话？</div>
        <div style="text-align: center;margin-bottom: 20px;">{{callPhone}}</div>
      </van-dialog>
      <!-- 底部tab -->
      <van-tabbar v-model="active">
        <van-tabbar-item replace to="/bank/index">
          <span>首页</span>
          <template #icon="props">
            <img v-if="props.active" src="../../../static/wowebsite/home-active.png" />
            <img v-else src="../../../static/wowebsite/home.png">
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/wowebsite/subbranch" icon="search">
          <span>支行</span>
          <template #icon="props">
            <img v-if="props.active" src="../../../static/wowebsite/subranch-active.png" />
            <img v-else src="../../../static/wowebsite/subranch.png">
          </template>
        </van-tabbar-item>
        <van-tabbar-item replace to="/wowebsite/my" icon="setting-o">
          <span>我的</span>
          <template #icon="props">
            <img v-if="props.active" src="../../../static/wowebsite/my-active.png" />
            <img v-else src="../../../static/wowebsite/my.png">
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
  import { vcardIndex,newVcardIndex } from "@/api/electronicCard";
  import { jingRongClassify, productList } from "@/api/electronicCard";
  import { getBankindex, getUserInfo, getbankShare } from '@/api/wowebsite'
  import { website } from "@/mixin/wowebsite.js"
  import { wxConfig } from "@/utils/wxCodeAuth";
  export default {
    mixins: [website],
    components: {},
    data() {
      return {
        pageLoading: false,
        value: '',
        // tabData: [
        //   {
        //     name: '预约',
        //     bannerImage: require('../../../static/wowebsite/yuyue.png'),
        //     router: '/wowebsite/appment'
        //   }, {
        //     name: '支行',
        //     bannerImage: require('../../../static/wowebsite/zhihang.png'),
        //     router: '/wowebsite/subbranch'
        //   }, {
        //     name: '咨询',
        //     bannerImage: require('../../../static/wowebsite/zixun.png')
        //   }, {
        //     name: '银行简介',
        //     bannerImage: require('../../../static/wowebsite/jianjie.png'),
        //     router: '/wowebsite/introduce'
        //   }],
        choose: 0,
        msg: "",
        productContent: [
          // { name: '银行理财1', text: '摘要摘要摘要摘要摘要摘要摘要摘要 摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要' },
          // { name: '银行理财1', text: '摘要摘要摘要摘要摘要摘要摘要摘要 摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要摘要' }
        ],
        productData: [],
        labelData: [],
        active: 0,
        homeData: {},
        activityData: '',//活动数据
        showPhoneCode: false,
        employeeId: '',//员工ID
        options: [],//分类数据
        callPhone: '',
        noticeData: [],
        dialogshow: false

      }
    },
    created() {
      if (this.$route.query.b) {
        this.b = this.$route.query.b;
        //拿到b之后共享到其他页面
        sessionStorage['wowebsiteFun'] = this.$route.query.b
      }
      this.getInfo()//获取用户信息
      //如果参数跟定位都存在，则获取首页数据
      if (this.b != '' && sessionStorage['userLoactionFun'] != undefined) {
        this.gethomeData()
      }
      this.shareData()
    },
    mounted() {

    },
    methods: {
      //获取分享内容
      shareData() {
        getbankShare({ b: this.b }).then((res) => {
          if (res.code == 200) {
            if (res.data.title) {
              let timestamp = new Date().getTime()
              //分享内容
              let shareOption = {
                title: res.data.title, // 分享标题
                desc: res.data.summary, // 分享描述
                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                imgUrl: res.data.image, // 分享图标
              };
              let shareFunction = function () {
                //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData(shareOption);
              };
              //加载微信配置
              wxConfig(shareFunction);
            }
          }
        })
      },
      //获取活动数据
      getActive(employeeId) {
        newVcardIndex({ employeeId: employeeId }).then((res) => {
          this.activityData = res.data.activity;
          this.noticeData = res.data.noticeList;
        });
      },
      //产品列表
      getProductData(id, employeeId) {
        productList({
          categoryId: id,
          employeeId: employeeId,
        }).then((res) => {
          this.productData = res.data.list;
        });
      },
      //获取产品分类
      getClassify(employeeId) {
        jingRongClassify({ employeeId: employeeId }).then((res) => {
          if (res.code == 200) {
            this.options = res.data;
            if (res.data.length != 0) {
              this.getProductData(res.data[0].id, this.employeeId)
            }
          }
        });
      },
      //获取首页数据
      gethomeData() {
        let params = {
          b: this.b
        }
        if (sessionStorage['userLoactionFun'] != undefined) {
          params.latitude = JSON.parse(sessionStorage['userLoactionFun']).latitude
          params.longitude = JSON.parse(sessionStorage['userLoactionFun']).longitude
        }
        getBankindex(params).then((res) => {
          if (res.code == 200) {
            if (res.data.list.length != 0) {
              //取第一位员工数据,获取活动以及产品数据
              if (res.data.list[0].employeeList && res.data.list[0].employeeList.length != 0) {
                this.employeeId = res.data.list[0].employeeList[0].id
                this.getActive(res.data.list[0].employeeList[0].id)
                // this.getProductData(res.data.list[0].employeeList[0].id)
                this.getClassify(res.data.list[0].employeeList[0].id)
              }
              res.data.list.forEach(item => {
                //位运算，将配置选项筛出
                let typeData = [1, 2, 4, 8, 16]//1=免费停车 2=ATM 4=CRS 8=助农终端 16=便民服务
                item.labelData = []
                typeData.map((ele) => {
                  if ((item.servicesInfo & ele) == ele) {
                    if (ele == 2) {
                      item.labelData.push({ name: 'ATM', color: '#FD8927' })
                    } else if (ele == 4) {
                      item.labelData.push({ name: '营业厅', color: '#7CD41D' })
                    }
                  }
                })
              });
              //第一项添加距离最近的标签
              res.data.list[0].labelData.unshift({ name: '距离最近', color: '#50B6FF' })
            }
            res.data.bankBannerImg = []//banner图片
            res.data.bankBannerBtn = []//banner按钮
            res.data.bankBannerList.forEach((item) => {
              if (item.type == 1) {
                res.data.bankBannerImg.push(item)
              } else if (item.type == 3) {
                res.data.bankBannerBtn.push(item)
              }
            })
            this.homeData = res.data;
            setTimeout(() => {
              this.pageLoading = false;
            }, 2000)
          }
        })
      },

      selectTab(row, index) {
        this.choose = index
        this.getProductData(row.id, this.employeeId)
      },
      //前往各页面
      goPage(row) {
        console.log("row", row)
        // contentType 1：文本 2：链接
        if (row.content == '') return;
        if (row.contentType == 1) {
          this.$router.push({
            path: "/wowebsite/richtext",
            query: { text: row.content },
          });
        } else if (row.contentType == 2) {
          location.href = row.content;
        }
        // if (row.name == '咨询') {
        //   if (this.homeData.shopCodeUrl == '') {
        //     this.$message.info('请耐心后台配置入口~！谢谢');
        //     return false
        //   }
        //   location.href = this.homeData.shopCodeUrl;
        // } else {
        //   this.$router.push({
        //     path: row.router,
        //     query: '',
        //   });
        // }

      },
      //前往搜索页面
      goSearch() {
        this.$router.push({
          path: '/wowebsite/search',
          query: '',
        });
      },
      //查看更多活动
      skipMoreAct() {
        if (this.employeeId == '') {
          this.$message.info('员工信息为空！');
          return false
        }
        this.$router.push({
          path: "/electronicCard/activity",
          query: {
            id: this.employeeId,
          },
        });
      },
      //点击前往海报详情
      skipActDetail() {
        if (this.activityData.url == "") {
          this.$router.push({
            path: "/electronicCard/activity/detail",
            query: {
              id: this.activityData.id,
              employeeId: this.employeeId,
            },
          });
        } else {
          location.href = this.activityData.url;
        }
      },
      //前往产品详情
      skipProductDetail(id) {
        this.$router.push({
          path: "/electronicCard/financialinfo/detail",
          query: {
            id,
            employeeId: this.employeeId
          },
        });
      },
      //公告
      skipToutiao() {
        if (this.noticeData.length != 0 && this.noticeData[0].url != "") {
          window.location.href = this.noticeData[0].url;
        } else {
          this.$router.push({
            path: "/electronicCard/notice",
            query: {
              id: this.employeeId
            },
          });
        }
      },
      //活动图点击
      bannerBtn(row) {
        // contentType 1：文本 2：链接
        if (row.content == '') return;
        if (row.contentType == 1) {
          this.$router.push({
            path: "/wowebsite/richtext",
            query: { text: row.content },
          });
        } else if (row.contentType == 2) {
          location.href = row.content;
        }
      },
      //获取用户信息
      getInfo() {
        let that = this;
        //如果为空数据  则重定向页面
        // getUserInfo({ b: this.b }).then(async (res) => {
        //   if (res.code == 200) {
        //     if (res.data.openid === undefined) {
        //       let redirectUrl = location.protocol + "//" + location.host + "/auth/bank?b=" + that.b + "&target=" + encodeURIComponent("/bank/index" + location.search);
        //       window.location.href = redirectUrl;
        //       return
        //     }
        //     this.$nextTick(() => {
        //       that.weChatUserInfo = res.data
        //       sessionStorage['userInfoFun'] = JSON.stringify(res.data)
        //     })
        this.gethomeData()//获取首页数据
        this.getlocationFun()//获取定位
        // this.$store.commit('userInfoFun', res.data);
        //   }
        // })
      },
      //获取定位
      getlocationFun() {
        var that = this;
        if (!sessionStorage['userLoactionFun']) {
          let getLocation = function () {
            wx.getLocation({
              type: 'wgs84',
              success: function (res) {
                console.log("位置信息", res)
                sessionStorage['userLoactionFun'] = JSON.stringify(res)
                that.gethomeData()//获取首页数据
              }
            });
          };
          //加载微信配置
          wxConfig(getLocation);
        }
      },


    }
  }
</script>

<style lang="less" scoped>
  .page {
    width: 100%;
    /* height: 100%; */
    background: #F9F9F9;
    padding: 0 19px 19px 19px;

    .loadBox {
      width: 100%;
      height: 100vh;
      /* display: flex;
      justify-content: center; */
      padding-top: 50px;
    }

    .banner {
      margin-top: 5px;
      /* background: #FFFFFF; */
      border-radius: 18px;
      width: 100%;
      height: 150px;
      /* background-image: url("../../../static/wowebsite/1@2x.png");
      background-size: cover; */
    }

    .tab {
      display: flex;
      justify-content: flex-start;
      /* flex-direction: row;
      flex-wrap: wrap; */
      padding-top: 15px;
      overflow-x: scroll;
      /* margin-bottom: 20px; */
      /* overflow: hidden;
      overflow-x:scroll; */

      .tab_box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* width: calc((100% - 20px) / 4); */
        width: 25%;
        /* width: 100px; */
        /* width: 80px; */
        height: 70px;
        /* &:first-child { margin-left: 20px;} */

        /* &:nth-child(n+2){
         margin-left: 60px;
        } */

        img {
          position: relative;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          width: 30px;
          height: 30px;
        }

        span {
          text-align: center;
          margin-top: 7px;
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

      }

    }

    .notice {
      /* margin-top: 20px; */
      width: 100%;
      height: 36px;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 12px 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;

      img {
        width: 29px;
        height: 11px;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
        color: #515151;
      }
    }

    .product {
      margin-top: 14px;
      padding: 15px 10px;
      background: #FFFFFF;
      border-radius: 10px;

      .title {
        font-size: 17px;
        font-weight: bold;
        color: #262626;
      }

      .product_tab {
        width: 100%;
        // overflow: hidden;
        overflow-x: scroll;
        white-space: nowrap;
        margin-top: 18px;
        display: inline-flex;

        :nth-child(n+2) {
          margin-left: 50px;
        }

        .product_list {

          .product_name {
            font-size: 16px;
            font-weight: 500;
            color: #C1C9D0;
          }

          .active {
            color: #2A8FF7;
            position: relative;
            z-index: 5;

            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              z-index: -1;
              top: 18px;
              left: 0px;
              width: 100%;
              height: 3px;
              background: #EEF1F9;
            }

          }
        }
      }

      .product_con {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;

        .product_con_left {
          width: 60%;

          .title {
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .content {
            margin-top: 13px;
            font-size: 12px;
            font-weight: 400;
            color: #959595;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .product_con_right {

          /* width: 45%; */
          img {
            width: 111px;
            height: 84px;
            border-radius: 4px;
          }
        }
      }
    }

    .huDong {
      margin-top: 13px;

      .huDong_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 17px;
          font-weight: bold;
          color: #262626;
        }

        .more {
          font-size: 12px;
          font-weight: 400;
          color: #717174;
        }
      }

      img {
        margin-top: 10px;
        width: 100%;
        height: 136px;
      }
    }

    .outlet {
      margin-top: 15px;

      .title {
        font-size: 17px;
        font-weight: bold;
        color: #262626;
      }

      .outlet_list {
        margin-top: 13px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 5px;
        padding: 15px 15px 12px;

        .list_top {
          display: flex;
          flex-direction: row;

          img {
            width: 62px;
            height: 62px;
          }

          .list_top_info {
            width: 75%;
            margin-left: 15px;

            .title {
              font-size: 15px;
              font-weight: bold;
              color: #323232;
            }

            .text {
              .text_top {
                display: flex;
                align-items: center;
                margin-top: 8px;
                font-size: 10px;
                font-weight: 400;
                color: #969696;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                img {
                  width: 10px;
                  height: 12px;
                  margin-right: 4px;
                }
              }

              .text_bottom {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 10px;
                font-weight: 400;
                color: #969696;


                img {
                  width: 10px;
                  height: 10px;
                  margin-right: 4px;
                }

                span {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }

        .line {
          margin-top: 8px;
          width: 100%;
          height: 1px;
          opacity: 0.37;
          background: #E8E8E8;
        }

        .list_bottom {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .label_left {
            :nth-child(n+2) {
              margin-left: 5px;
            }

            display: flex;
            flex-direction: row;

            .lebal_box {
              border-radius: 3px;
              padding: 1px 8px;
              font-size: 9px;
              font-weight: 400;
              color: #FFFFFF;
              height: 15px;
              line-height: 15px;
              text-align: center;
            }

          }

          .label_right {
            display: flex;
            flex-direction: row;

            .right_box {
              img {
                width: 11px;
                height: 11px;
              }

              span {
                margin-left: 4px;
                font-size: 10px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  /deep/ .van-search {
    background: #F9F9F9 !important;
    padding: 10px 0 !important
  }

  /deep/ .van-search__content {
    background-color: #FFFFFF
  }

  .my-swipe .van-swipe-item {
    color: #fff;
    /* background: #FFFFFF; */
    border-radius: 18px;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    /* background-color: #39a9ed; */
  }

  .phoneT {
    text-align: center;
    margin: 38px 38px 0 38px;
    height: 35px;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
  }

  .fixedBox {
    position: fixed;
    right: 14px;
    bottom: 50%;
  }

  .address {
    margin: 17px 0 16px 20px;
    height: 21px;
    font-size: 15px;
    color: #333333;
    line-height: 21px;
  }

  .url {
    margin: 0 0 20px 20px;
    font-size: 13px;
    color: #999999;
    line-height: 19px;
  }

  .close {
    font-size: 16px;
    float: right;
    margin-right: 14px;
    margin-top: -4px;
  }
</style>