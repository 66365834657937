<template>
    <div class="page">
        <!-- <baidu-map class='bm-view' @ready='init'></baidu-map> -->

        <div class="map_box">
            <!-- <div id="container"></div> -->
            <baidu-map class='bm-view' :center="center" :zoom="zoom" @ready="handler">
                <!-- <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker> -->
                <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                    <!-- <bm-info-window :show="true" >{{detailData.name}}</bm-info-window> -->
                    <!-- <bm-label :content="detailData.name" :labelStyle="{color: '#000000', fontSize : '12px',marginTop:'-70px',padding:'6px',borderColor:'#FFFFFF',borderRadius:'20px'}" :offset="{width: -35, height: 30}"/> -->

                </bm-marker>
            </baidu-map>
        </div>
        <div class="content">
            <div class="head">
                <img :src="detailData.bankImage" alt="">
                <span>{{detailData.name}}</span>
            </div>
            <!-- 支行信息 -->
            <div class="info">
                <div class="info_left">
                    <div class="text">
                        <img style="width:8px;height:10px" src="../../../static/wowebsite/loaction.png" alt="">
                        <span>{{detailData.address}}</span>
                    </div>
                    <div class="text">
                        <img style="width:9px;height:8px" src="../../../static/wowebsite/time.png" alt="">
                        <span>周一至周五:{{detailData.starttimes}}-{{detailData.endtimes}}{{detailData|weekReslt}}</span>
                    </div>
                    <div class="text">
                        <img style="width:9px;height:9px" src="../../../static/wowebsite/lianxi.png" alt="">
                        <span>{{detailData.mobile}}</span>
                    </div>
                </div>
                <div class="info_right" @click="goNav(detailData)">
                    <img src="../../../static/wowebsite/goloction.png" alt="">
                    <span>{{detailData.farShow}}</span>
                </div>
            </div>
            <!-- 活动图 -->
            <div class="banner" v-if="detailData.bankBannerList&&detailData.bankBannerList.length!=0">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                    <div v-for="(item,index) in detailData.bankBannerList" :key="index">
                        <van-swipe-item>
                            <img style="width: 100%;height: 109px;" :src="item.bannerImage" @click="bannerBtn(item)"
                                alt="">
                        </van-swipe-item>
                    </div>
                </van-swipe>
                <!-- <img src="../../../static/wowebsite/banner2@2x.png" alt=""> -->
            </div>
            <!-- 人物  :style="index % 4 === 0?'margin-left:0':''"-->
            <div class="figure">
                <div class="figure_box" v-for="(item,index) in detailData.employeeList" :key="index"
                    @click="goEmployee(item)">
                    <img style="width: 73px;height: 73px" :src="item.avatar" alt="">
                    <span class="title">{{item.name}}</span>
                    <span class="name">{{item.position==''?'暂无职位':item.position}}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getbankHome, getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {

        },
        data() {
            return {
                active: 2,
                list: [{
                    img: require('../../../static/wowebsite/wodeyuyue-2@2x.png'),
                    name: '我的预约'
                }, {
                    img: require('../../../static/wowebsite/navicon-yhxy@2x.png'),
                    name: '用户协议'
                }],
                figureData: [{}, {}, {}, {}, {}, {}, {}, {}],
                //BMap类
                BMap: null,
                //地图对象
                map: null,
                center: { lng: 0, lat: 0 },
                zoom: 16,
                detailData: {}
            }

        },
        created() {
            //存在传参
            if (this.$route.query) {
                this.getDetail(this.$route.query)
            }
            this.shareData(sessionStorage['wowebsiteFun'])
        },
        filters: {
            //处理周末时间展示
            weekReslt(item) {
                //位运算，将周末选项筛出
                let typeWeek = [1, 2];//周6 周日
                let weekend = []
                typeWeek.map((ele) => {
                    if ((item.weekend & ele) == ele) {
                        weekend.push(ele)
                    }
                })
                if (weekend.length == 2) {
                    return '周末:' + item.zmstartimes + '-' + item.zmendtimes
                } else if (weekend.length == 1 && weekend[0] == 1) {
                    return '周六:' + item.zmstartimes + '-' + item.zmendtimes
                } else if (weekend.length == 1 && weekend[0] == 2) {
                    return '周日:' + item.zmstartimes + '-' + item.zmendtimes
                }

            },

        },
        mounted() {
        },
        methods: {
            //获取分享内容
            shareData(b) {
                getbankShare({ b: b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            //分享内容
                            let timestamp = new Date().getTime()
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            //获取某个支行详情
            getDetail(params) {
                getbankHome(params).then((res) => {
                    if (res.code == 200) {
                        let point = this.qqMapToBMap(res.data.longitude, res.data.latitude)
                        res.data.bmaplongitude = point[0]
                        res.data.bmaplatitude = point[1]
                        console.log("point", point)
                        this.center.lng = point[0]
                        this.center.lat = point[1]
                        this.detailData = res.data

                    }

                })
            },
            //坐标系转化  腾讯地图转百度地图
            qqMapToBMap(lng, lat) {
                if (lng == null || lng == '' || lat == null || lat == '')
                    return [lng, lat];
                var x_pi = 3.14159265358979324;
                var x = parseFloat(lng);
                var y = parseFloat(lat);
                var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
                var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
                var lng = (z * Math.cos(theta) + 0.0065).toFixed(5);
                var lat = (z * Math.sin(theta) + 0.006).toFixed(5);
                return [lng, lat];

            },
            async handler({ BMap, map }) {
                // console.log(BMap, map)
                this.center.lng = this.detailData.bmaplongitude ? this.detailData.bmaplongitude : 116.404
                this.center.lat = this.detailData.bmaplatitude ? this.detailData.bmaplatitude : 39.915
                // this.center.lng =  114.030302
                // this.center.lat =22.610572
                this.zoom = 14
            },
            //活动图点击
            bannerBtn(row) {
                // contentType 1：文本 2：链接
                if (row.content == '') return;
                if (row.contentType == 1) {
                    this.$router.push({
                        path: "/wowebsite/richtext",
                        query: { text: row.content },
                    });
                } else if (row.contentType == 2) {
                    location.href = row.content;
                }
            },
            //打开内置地图
            goNav(row) {
                // console.log("详情前往地图", row)
                let openLocation = function () {
                    wx.openLocation({
                        latitude: parseFloat(row.latitude), // 纬度，浮点数，范围为90 ~ -90
                        longitude: parseFloat(row.longitude), // 经度，浮点数，范围为180 ~ -180。
                        name: row.name, // 位置名
                        address: row.address, // 地址详情说明
                        scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
                        //   infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
                    });

                };
                wxConfig(openLocation);
            },
            //前往员工
            goEmployee(row) {
                this.$router.push({ path: '/vcard/record', query: { id: row.id } })
            }

        }
    }
</script>

<style lang="less" scoped>
    .page {
        /* background: #F9F9F9; */
        // padding: 15px 12px 19px 12px;
        height: 100%;

        .map_box {
            width: 100%;
            height: 318px;
            background: #D8D8D8;
        }

        .content {
            position: relative;
            z-index: 999;
            width: 100;
            background: #FFFFFF;
            padding: 12px;
            height: auto;
            margin-top: -20px;
            border-radius: 16px 16px 0px 0px;

            .head {
                display: flex;
                align-items: center;

                img {
                    width: 17px;
                    height: 17px;
                }

                span {
                    margin-left: 5px;
                    font-size: 15px;
                    font-weight: bold;
                    color: #323232;
                }
            }

            .info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-top: 1px solid #E8E8E8;
                border-bottom: 1px solid #E8E8E8;
                margin-top: 8px;
                padding: 8px 0;

                .info_left {
                    .text {
                        img {
                            width: 8px;
                            height: 8px;
                        }

                        span {
                            margin-left: 6px;
                            font-size: 11px;
                            font-weight: 400;
                            color: #969696;
                        }

                    }
                }

                .info_right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 32px;
                        height: 32px;
                    }

                    span {
                        margin-top: 6px;
                        font-size: 8px;
                        font-weight: 400;
                        color: #8E8E8E;
                    }
                }
            }

            .banner {
                margin-top: 12px;

                img {
                    width: 100%;
                    height: 109px;
                }
            }

            .figure {
                margin-top: 2px;
                /* display: flex;
                // flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                ::after {
                    content: "";
                    width: auto;
                    flex: auto;
                } */
                display: flex;
                /* flex布局 */

                justify-content: flex-start;
                /* 左对齐 */

                flex-wrap: wrap;

                /* 换行 */
                .figure_box {
                    // margin-right: 20px;   /* 每个元素右间距设置为20px */

                    /* 计算每个元素的宽度：20px是前三个元素的间距（margin-right）和，除以4即为每个元素的宽度 */

                    width: calc(100% / 4);
                    margin-top: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    justify-content: center;
                    /* width: calc((100% - 20px) / 4); */
                    /* width:25%; */
                    /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12); */
                    // margin-left:24px;
                    box-shadow: 0px 1px 3px 0px rgba(67, 67, 67, 0.08);
                    border-radius: 4px 4px 0px 0px;

                    img {
                        margin: 0 auto;

                    }

                    .title {
                        width: 100%;
                        margin-top: 7px;
                        font-size: 13px;
                        text-align: center;
                        color: #323232;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                    }

                    .name {
                        width: 100%;
                        text-align: center;
                        margin-top: 2px;
                        font-size: 9px;
                        color: #969696;
                        margin-bottom: 2px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                    }

                }
            }
        }
    }

    .bm-view {
        width: 100%;
        height: 100%;
    }
</style>