<template>
    <div class="page">
        <div class="head">
            <div class="title">{{detailJson.status| reStatus}}</div>
            <div class="tips">请您于{{detailJson.serviceTimeFormat}}前达到指定网点办理</div>
        </div>
        <div class="info">
            <div class="info_title">订单信息</div>
            <div class="info_con" style="margin-top: 10px;">
                <span class="con1">预约编号</span>
                <span class="con2">{{detailJson.orderNo}}</span>
            </div>
            <div class="info_con">
                <span class="con1">姓名</span>
                <span class="con2">{{detailJson.name}}</span>
            </div>
            <div class="info_con">
                <span class="con1">手机号</span>
                <span class="con2">{{detailJson.mobile}}</span>
            </div>
            <div class="info_con">
                <span class="con1">预约业务</span>
                <span class="con2">{{detailJson.serviceName}}</span>
            </div>
            <div class="info_con">
                <span class="con1">支行</span>
                <span class="con2">{{detailJson.bankName}}</span>
            </div>
            <div class="info_con">
                <span class="con1">客户经理</span>
                <span class="con2">{{detailJson.employeeName}}</span>
            </div>
            <div class="info_con">
                <span class="con1">申请时间</span>
                <span class="con2">{{detailJson.createdAt}}</span>
            </div>
            <div class="info_con">
                <span class="con1">预约时间</span>
                <span class="con2">{{detailJson.serviceTimeFormat}}</span>
            </div>
        </div>
        <div class="btn_box">
            <van-button style="width: 48%;background: #EBEBEB;" round type="default"
                @click="callBtn(1)">拨打支行电话</van-button>
            <van-button style="width: 48%;margin-left: 10px;" round type="info"
                @click="callBtn(2)">拨打客户经理电话</van-button>
        </div>
        <van-dialog v-model="showPhoneCode" show-cancel-button cancelButtonText="否" @confirm="confirmTel"
            confirmButtonText="是" confirmButtonColor="#3974C6">
            <div class="phoneT">是否拨打以下电话？</div>
            <div style="text-align: center;margin-bottom: 20px;">{{callPhone}}</div>
        </van-dialog>
    </div>
</template>
<script>
    import { recordDetail, getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {

        },
        data() {
            return {
                id: '',
                b: '',//跳转链接参数
                detailJson: {},
                callPhone: '',
                showPhoneCode: false
            }
        },
        created() {
            this.id = this.$route.query.id;
            // this.b =this.$store.state.wowebsiteB
            this.b = sessionStorage['wowebsiteFun']
            this.getData()
            this.shareData()
        },
        filters: {
            reStatus(value) {
                //预约状态:0已取消 1已提交 2已完成
                let json = {
                    0: '已取消',
                    1: '已提交',
                    2: '已完成'
                }
                return json[value]
            },
        },
        mounted() {
        },
        methods: {
            //获取分享内容
            shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            getData() {
                let params = {
                    id: this.id,
                    b: this.b,//跳转链接参数
                    union_id: JSON.parse(sessionStorage['userInfoFun']).unionid,
                }
                recordDetail(params).then((res) => {
                    if (res.code == 200) {
                        this.detailJson = res.data
                    }
                })
            },
            callBtn(type) {
                type == 1 ? this.callPhone = this.detailJson.bankMobile : this.callPhone = this.detailJson.employeeMobile
                this.showPhoneCode = true;
            },
            //拨打电话
            confirmTel() {
                window.location.href = `tel:${this.callPhone}`;
            }
        }
    }
</script>
<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        height: 100%;

        .head {
            width: 100%;
            padding: 27px 15px;
            background: #FFFFFF;
            text-align: center;

            .title {
                font-size: 16px;
                font-weight: bold;
                color: #262626;
            }

            .tips {
                margin-top: 5px;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .info {
            margin-top: 10px;
            padding: 15px 20px;
            background: #FFFFFF;

            .info_title {
                font-size: 16px;
                font-weight: bold;
                color: #000000;
            }

            .info_con {
                width: 100%;
                display: flex;
                justify-content: space-around;
                margin-top: 5px;

                .con1 {
                    width: 20%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515151;
                }

                .con2 {
                    width: 80%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #515151;
                }
            }
        }

        .btn_box {
            padding: 20px;
        }
    }

    .phoneT {
        text-align: center;
        margin: 38px 38px 0 38px;
        height: 35px;
        font-size: 16px;
        color: #333333;
        line-height: 24px;
    }
</style>