//节流
export default function (func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(context, args);
        }, wait);
      }
    };
  }