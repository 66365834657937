import {request,request_op} from "../plugins/axios";

export function verifyUserInfo (params) {
    return request_op({
        url: '/openUserInfo/StoreContactInfo',
        method: 'post',
        params: params
    })
}
//获取用户信息基本信息
export function openUserInfoApi(params) {
    return request_op({
        url: 'openUserInfo/workContact',
        method: 'get',
        params
    })
}