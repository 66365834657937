<template>
    <div class="prizePage">
        <!-- 样式1 -->
        <!-- <div class="prizeBox">
            <div class="boxTop">
                <div class="cenBox">
                    <div class="prize">
                        <div class="itemBox">
                            <div class="container">
                                <div v-for="item of 9" :class="chelkAtive==item?'tagCheck':''" @click="tagBtn(item)">
                                    <img src="../../../assets/images/questions/answerIcon.png" alt="">
                                    <span>文字</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="prizeTips">本次剩余1次领取机会，领取后点击对应奖品可兑奖核销！</div>
                    <div class="prizeBtn">
                        <img src="../../../assets/images/questions/prizeBtn.png" alt="" @click="receive">
                    </div>
                    <div class="closeImg">
                        <img  src="../../../assets/images/questions/close.png" alt="" @click="closeFun">
                    </div>
                </div>
            </div>
        </div> -->
        <!-- 样式2 -->
        <div class="boxBack">
            <div class="container">
                <div v-for="(item,index) in prizelist" :class="chelkAtive===index?'tagCheck':''" :key="index" @click="tagBtn(index)">
                    <img :src="item.image_show" alt="">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="prizeTips">本次剩余1次领取机会，领取后点击对应奖品可兑奖核销！</div>
            <div class="prizeBtn">
                <img src="../../../assets/images/questions/prizeBtn.png" alt="" @click="receive">
            </div>
            <div class="closeImg">
                <img src="../../../assets/images/questions/close.png" alt="" @click="closeFun">
            </div>
        </div>
    </div>
</template>
<script>
    import { getPrizeList } from '@/api/questions'
    import throttle from '../utils/throttle';
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                chelkAtive: '',
                prizelist: [],
                cerrentScore:0,//当前分数值
            }
        },
        created() {
            this.getData()
        },
        methods: {
            async getData(cerrentScore) {
                this.cerrentScore=cerrentScore;
                try {
                    const { code, data, msg } = await getPrizeList({ id: this.$route.query.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid })
                    if (code === 200) {
                        if (data.code === 0) {
                            this.prizelist = data.data
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {
                }
            },
            createPage() {
                Object.assign(this.$data, this.$options.data.call(this))
            },
            tagBtn(index) {
                this.chelkAtive = index
            },
            receive: throttle(function() {
                this.showType = 1
                this.childShow = true
                //判断是否选择奖品
                if(this.chelkAtive===''){
                    this.$toast("请选择奖品!");
                    return
                }
                // //判断奖品分值是否能达到
                if(this.cerrentScore<this.prizelist[this.chelkAtive].score){
                    this.$toast("答题分数不足以领取此奖品");
                    return
                }
                this.$emit('childSubmit', {id:this.$route.query.id,prizeDetail:this.prizelist[this.chelkAtive]});
            }, 500),
            closeFun() {
                this.$emit('closeFun', '');
            }
        }
    }
</script>
<style lang="less" scoped>
    .prizePage {
        .prizeBox {
            padding: 14px;
            margin-top: 126px;
            .boxTop {
                width: 100%;
                height: 21px;
                background: #FFA17D;
                border-radius: 5px;
                padding: 0px 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                .cenBox {
                    width: 100%;
                    height: 8px;
                    background: #FF6F54;
                    border-radius: 5px;
                    padding: 0px 6px;
                    .prize {
                        margin-top: 5px;
                        width: 100%;
                        background: #FFF5E4;
                        padding: 20px;
                        .itemBox {
                            background-image: url("../../../assets/images/questions/prizeBack.png");
                            background-size: 100% 100%;
                            width: 100%;
                            /* height: 276px; */
                            /* background: #FF732D; */
                            padding: 15px 8px;
                            .container {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                padding: 0px 8px;
                            }
                            .tagCheck {
                                background: #FFDC03 !important;
                            }
                            .container div {
                                width: 30%;
                                height: 69px;
                                margin: 7px 4px 7px 4px;
                                background: #FFFFFF;
                                border-radius: 6px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-direction: column;
                                img {
                                    width: 36px;
                                    height: 36px;
                                }
                                span {
                                    width: 100%;
                                    text-align: center;
                                    margin-top: 3px;
                                    font-size: 11px;
                                    font-weight: 400;
                                    color: #6C6C6C;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                    .prizeTips {
                        margin-top: 15px;
                        text-align: center;
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                    .prizeBtn {
                        margin-top: 30px;
                        width: 100%;
                        text-align: center;
                        img {
                            width: 252px;
                            height: 54px;
                        }
                    }
                    .closeImg {
                        margin-top: 30px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
        .boxBack {
            width: 100%;
            height: 370px;
            background-image: url("../../../assets/images/questions/prizeBack2.png");
            background-size: 100% 100%;
            .container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 0px 50px;
                padding-top: 150px;
            }
            .tagCheck {
                background: #FFDC03 !important;
            }
            .container div {
                width: 30%;
                height: 69px;
                margin: 7px 4px 7px 4px;
                background: #FFFFFF;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                img {
                    width: 36px;
                    height: 36px;
                }
                span {
                    width: 100%;
                    text-align: center;
                    margin-top: 3px;
                    font-size: 11px;
                    font-weight: 400;
                    color: #6C6C6C;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .prizeTips {
                margin-top: 15px;
                text-align: center;
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
            }
            .prizeBtn {
                margin-top: 30px;
                width: 100%;
                text-align: center;
                img {
                    width: 252px;
                    height: 54px;
                }
            }
            .closeImg {
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
</style>