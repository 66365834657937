<template>
  <div class="all">
    <div class="top">
      <img src="../../assets/images/safe.png" alt="" />
    </div>
    <div class="info">信息安全认证</div>
    <van-form validate-first @submit="onSubmit">
      <van-field
        v-model="value1"
        name="validatorName"
        placeholder="请输入本人姓名"
        :rules="[{  validator:validatorName, message: '请输入正确姓名' }]"
      />
      <van-field
        v-model="value2"
        name = "validatorCard"
        placeholder="请输入身份证号码"
        :rules="[{  validator:validatorCard, message: '请输入正确身份证号' }]"
      />
      <van-field
        v-model="value3"
        name = "validatorPhone"
        placeholder="请输入手机号码"
        :rules="[{  validator:validatorPhone, message: '请输入正确手机号' }]"
      />
      <div style="margin: 58px 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import { verifyUserInfo, openUserInfoApi} from "@/api/identify";

export default {
  data() {
    return {
      value1: "",
      value2: "",
      value3: "",
      employeeId: '',
      unionid:''
    };
  },
  created() {
      this.employeeId = this.$route.query.employee_id;
      console.log(this.employeeId);
      this.getOpenUserInfo();
    },
  methods: {
    getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          employee_id: that.employeeId,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/workContact?employee_id=" +
              that.employeeId +
              "&target=" +
              encodeURIComponent(
                "/identify" + location.search
              );
            console.log("跳转链接", redirectUrl);
            window.location.href = redirectUrl;
            return;
          }
          console.log("res.data", res.data);
          this.unionid = res.data.unionid;
          console.log("openid", this.unionid);
        });
      },
    // 校验函数返回 true 表示校验通过，false 表示不通过
    validatorName(val){
      return /^[\u4e00-\u9fa5]{2,4}$/.test(val);
    },
    validatorCard(val) {
      return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
    },
    validatorPhone(val){
        return /^1[3456789]\d{9}$/.test(val);
    },
    onSubmit(values) {
      console.log("success", values);
      verifyUserInfo({ employee_id:this.employeeId, name:this.value1, id_card:this.value2, mobile:this.value3}).then(res=>{
        if(res.code==200){
          this.$router.push('/identify/success')
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-cell {
  padding: 17px;
}
.top {
  text-align: center;
  img {
    margin: 38px 0 26px 0;
    width: 219px;
    height: 219px;
  }
}
.info {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #323232;
  margin-bottom: 28px;
}
</style>