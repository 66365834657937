<template>
    <div class="page">
        <div class="tips">办理业务需要您携带身份证原件等前往支行办理，线上申请后到场出示预约信息。</div>
        <van-form @submit="onSubmit">
            <van-field v-model="params.name" name="name" label="姓名" placeholder="请输入真实姓名"
                :rules="[{ required: true, message: '请输入真实姓名' }]" />
            <van-field readonly clickable name="service_id" :value="params.service_id" label="预约业务"
                placeholder="请选择预约业务" @click="showPicker = true" :rules="[{ required: true, message: '请选择预约业务' }]" />
            <van-field readonly clickable name="bank_id" :value="params.bank_id" label="选择支行" placeholder="请选择支行"
                @click="showbrach = true" :rules="[{ required: true, message: '请选择支行' }]" />
            <van-field readonly clickable name="employee_id" :value="params.employee_id" :disabled="params.bank_id==''"
                @click="ConnectionbBtn()" label="客户经理" placeholder="请选择支行客户经理"
                :rules="[{ required: true, message: '请选择支行客户经理' }]" />
            <van-field readonly clickable name="service_time" v-model="params.service_time" label="预约日期"
                placeholder="点击选择日期" @click="showCalendar = true" />
            <van-field v-model="params.mobile" name="mobile" label="手机号码" placeholder="请输入手机号码"
                :rules="[{ required: true, message: '请输入手机号码' }]" />
            <!-- <van-field v-model="params.value7" center clearable label="短信验证码" placeholder="请输入短信验证码">
                <template #button>
                    <van-button size="small" type="info" plain hairline round>发送验证码</van-button>
                </template>
            </van-field> -->
            <!-- 预约业务弹窗 -->
            <van-popup v-model="showPicker" position="bottom">
                <van-picker show-toolbar :columns="bankServiceRecordList" @confirm="onConfirm1"
                    @cancel="showPicker = false" />
            </van-popup>
            <!--支行弹窗 -->
            <van-popup v-model="showbrach" position="bottom">
                <van-picker show-toolbar :columns="bankRecordList" @confirm="onConfirm2" @cancel="showbrach = false" />
            </van-popup>
            <!--客户经理 -->
            <van-popup v-model="showConnection" position="bottom">
                <van-picker show-toolbar :columns="bankManagerRecordList" @confirm="onConfirm3"
                    @cancel="showConnection = false" />
            </van-popup>
            <!-- 日期弹窗 -->
            <!-- <van-calendar v-model="showCalendar" @confirm="onConfirm5" /> -->
            <van-popup v-model="showCalendar" position="bottom">
                <van-datetime-picker @cancel="showCalendar=false" @confirm="onConfirm4" v-model="currentDate"
                    type="datetime" :min-date="minDate" title="选择完整时间" />
            </van-popup>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">提交预约申请</van-button>
            </div>
        </van-form>

    </div>
</template>

<script>
    import { setAppment, bankServiceRecord, bankRecord, bankManagerRecord, getbankShare, getUserInfo } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    Date.prototype.format = function (format) {
        var o = {
            "M+": this.getMonth() + 1, //month  
            "d+": this.getDate(), //day  
            "h+": this.getHours(), //hour  
            "m+": this.getMinutes(), //minute  
            "s+": this.getSeconds(), //second  
            "q+": Math.floor((this.getMonth() + 3) / 3), //quarter  
            "S": this.getMilliseconds() //millisecond  
        }
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format))
                format = format.replace(RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] :
                        ("00" + o[k]).substr(("" + o[k]).length));
        return format;
    }
    export default {
        components: {

        },
        data() {
            return {

                showCalendar: false,
                showPicker: false,
                showbrach: false,
                showConnection: false,
                columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
                columns2: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
                currentDate: new Date(),
                params: {
                    service_id: '',
                    bank_id: '',
                    name: '',
                    employee_id: '',
                    service_time: '',
                    mobile: '',
                },
                bankServiceRecordList: [],
                priBankServiceRecordList: [],//原数组数据
                bankRecordList: [],
                priBankRecordList: [],//原数组数据
                bankManagerRecordList: [],
                priBbankManagerRecordList: [],//原数组数据
                b: '',//跳转链接参数,
                minDate: new Date(),
            }
        },
        created() {
            this.b = sessionStorage['wowebsiteFun']
            this.getInfo()
        },
        mounted() {
            var that = this
            //监听页面返回
            if (window.history && window.history.pushState) {
                window.history.pushState(null, null, document.URL)
                window.addEventListener('popstate', () => {
                    // 重定向你要的地址即可
                    that.$router.replace(`/bank/index`)
                }, false)
            }
        },
        methods: {
            //获取用户信息
            getInfo() {
                let that = this;
                //如果为空数据  则重定向页面
                getUserInfo({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.openid === undefined) {
                            let redirectUrl = location.protocol + "//" + location.host + "/auth/bank?b=" + that.b + "&target=" + encodeURIComponent("/wowebsite/appment" + location.search);
                            window.location.href = redirectUrl;
                            return
                        }
                        sessionStorage['userInfoFun'] = JSON.stringify(res.data)
                        this.getlist()//获取各列表数据
                        this.shareData()
                    }
                })
            },
            //获取分享内容
            shareData() {
                getbankShare({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            getlist() {
                //业务列表
                bankServiceRecord({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        this.priBankServiceRecordList = res.data;
                        this.bankServiceRecordList = res.data.map((ele) => {
                            return ele.serviceName
                        })

                    }
                })
                //支行列表
                bankRecord({ b: this.b }).then((res) => {
                    if (res.code == 200) {
                        this.priBankRecordList = res.data;
                        this.bankRecordList = res.data.map((ele) => {
                            return ele.name
                        })

                    }
                })
            },
            //客户经理列表
            getbankManagerRecord(id) {
                bankManagerRecord({ bankId: id }).then((res) => {
                    if (res.code == 200) {
                        this.priBbankManagerRecordList = res.data;
                        this.bankManagerRecordList = res.data.map((ele) => {
                            return ele.name
                        })
                    }
                })
            },
            onConfirm5(date) {
                this.params.calendar = `${date.getMonth() + 1}/${date.getDate()}`;
                this.showCalendar = false;
            },
            //业务选择
            onConfirm1(value) {
                this.params.service_id = value;
                this.showPicker = false;
            },
            //支行选择
            onConfirm2(value) {
                //选择完后 传递ID获取客户经理数据
                let newlist = this.priBankRecordList.filter(item => item.name === value);//过滤数据
                this.getbankManagerRecord(newlist[0].id)
                this.params.bank_id = value;
                this.showbrach = false;
            },
            ConnectionbBtn() {
                if (this.params.bank_id != '') {
                    this.showConnection = true;
                }
            },
            //客户经理选择
            onConfirm3(value) {
                //选择完后 传递ID获取客户经理数据
                this.params.employee_id = value;
                this.showConnection = false;
            },
            //时间日期
            onConfirm4(value) {
                this.params.service_time = new Date(this.currentDate).format("yyyy-MM-dd hh:mm:ss")
                this.showCalendar = false;
            },
            onSubmit(values) {
                console.log('submit', values, this.params);
                let params = {
                    ...this.params,
                    b: this.b,
                    union_id: JSON.parse(sessionStorage['userInfoFun']).unionid
                }
                //业务ID 
                params.service_id = this.priBankServiceRecordList.filter(item => item.serviceName === this.params.service_id)[0].id;//过滤数据
                //支行ID
                params.bank_id = this.priBankRecordList.filter(item => item.name === this.params.bank_id)[0].id;//过滤数据
                //员工ID
                params.employee_id = this.priBbankManagerRecordList.filter(item => item.name === this.params.employee_id)[0].id;//过滤数据
                setAppment(params).then((res) => {
                    if (res.code == 200) {
                        this.$router.push({
                            path: '/wowebsite/callback',
                            query: { id: res.data.id, serviceName: this.params.service_id },
                        });
                    }
                })


            },
        }
    }
</script>

<style lang="less" scoped>
    .page {
        background: #F9F9F9;

        .tips {
            padding: 0 19px 19px 19px;
            padding-top: 10px;
            font-size: 12px;
            font-weight: 400;
            color: #717174;
        }

    }
</style>