// 微官网模块 公共方法
import { getUserInfo, getEmpowerInfo } from '@/api/wowebsite'
import { wxConfig } from "@/utils/wxCodeAuth";
export const website = {
    components: {

    },
    data() {
        return {
            b:'',//跳转链接参数
            weChatUserInfo: {
                // city: "",
                // country: "",
                // headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/DPKgeX7hrGibzFFrdIqiaYTiaqYibJCibjkXEylgjD92dFbQia8hK5qPce1FH8LctoF4ErOmtAgOFzv7MJsJpmOWL1Rg/132",
                // language: "",
                // nickname: "韧真",
                // openid: "oWx9w6Gcpp-Hft-K_bqLQl9XtRfI",
                // privilege: [],
                // province: "",
                // sex: 0,
                // unionid: "o7W6Z5uUfEJ81Adevo_-5xlFL1eU"
            },//微信用户信息
        }
    },
    created() {
        this.b=sessionStorage['wowebsiteFun']
    },
    filters: {
        //处理周末时间展示
        weekReslt(item) {
            //位运算，将周末选项筛出
            let typeWeek = [1, 2];//周6 周日
            let weekend = []
            typeWeek.map((ele) => {
                if ((item.weekend & ele) == ele) {
                    weekend.push(ele)
                }
            })
            if (weekend.length == 2) {
                return '周末:' + item.zmstartimes + '-' + item.zmendtimes
            } else if (weekend.length == 1 && weekend[0] == 1) {
                return '周六:' + item.zmstartimes + '-' + item.zmendtimes
            } else if (weekend.length == 1 && weekend[0] == 2) {
                return '周日:' + item.zmstartimes + '-' + item.zmendtimes
            }

        },

    },
    mounted() {   
    },
    methods: {
        //前往支行详情
        goDetail(row) {
            let params = {
                b: this.b,
                bankId: row.id
            }
            if(sessionStorage['userLoactionFun']!=undefined){
                params.latitude=JSON.parse(sessionStorage['userLoactionFun']).latitude,
                params.longitude=JSON.parse(sessionStorage['userLoactionFun']).longitude
            }
            this.$router.push({
                path: "/wowebsite/branchDetail",
                query: params,
            });
        },
        //打开内置地图
        goNav(row) {
            console.log("前往地图",row)
            let openLocation = function () {
            //    wx.getLocation({
            //       type: 'wgs84',
            //       success: function (res) {
                    // console.log("位置信息",res)
                    wx.openLocation({
                        latitude: parseFloat(row.latitude), // 纬度，浮点数，范围为90 ~ -90
                        longitude: parseFloat(row.longitude), // 经度，浮点数，范围为180 ~ -180。
                        name: row.name, // 位置名
                        address: row.address, // 地址详情说明
                        scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
                        //   infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
                    });
                //   }
                // })
              
            };
            wxConfig(openLocation);
         
        },
        //拨打电话
        openPhone(row) {
            this.callPhone = row.mobile
            this.showPhoneCode = true;
        },
        //拨打电话
        confirmTel() {
            window.location.href = `tel:${this.callPhone}`;
        },
    }
}