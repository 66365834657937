<template>
    <div class="page">
        <img :src="imgPath" alt="">
    </div>
</template>
<script>
    export default {
        data() {
            return {
                imgPath: ''
            };
        },
        created() {
            this.imgPath = this.$route.query.path
        },
    };
</script>
<style lang="less" scoped>
    .page{
        width: 100%;
        img{
            width: 100%;
        }
    }
</style>