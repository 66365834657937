<template>
    <div class="detailpage">
        <div class="score">
            <div class="scoreLeft">
                <span class='name'>答题赢好礼</span>
                <span class="tips">（正确：{{scoreData.rightNum}}题，错误：{{scoreData.errorNum}}题）</span>
            </div>
            <div class="scoreright" v-if="scoreData.showGrades==1">{{scoreData.score}}分</div>
        </div>
        <div class="line"></div>
        <div class="itemBox">
            <div v-for="(item,index) in scoreData.answerRecord">
                <div class="content" >
                    <div class="topicType">{{showType(item.type)}}</div>
                    <div class="topicCon">
                        <div class="subscript"><span>{{index | sliceMsg}}</span></div>
                        <div class="quest">{{item.question}}</div>
                    </div>
                    <div class="questLine"></div>
                    <div class="questImg" v-if="item.questionImage!=''">
                        <img :src="item.questionImage" alt="">
                    </div>
                     <!-- 单选题||多选题 -->
                    <div class="questList" v-if="item.type==1||item.type==2">
                        <div v-for="(val, key) in item.option" :key="key">
                            <div class="liSelect" :class="{ tagCheck: isChosen(item.choose,key) }">{{ key }}：{{ val }}</div>
                          </div>
                    </div>
                    <!-- 问答 -->
                    <div class="questInput" v-if="item.type==3||item.type==4">
                        <van-field v-model="item.choose" readonly  rows="2" autosize type="textarea" placeholder="您的回答" show-word-limit
                            class="vanField" />
                    </div>
                   <!-- answerDisplay：1 显示答案 -->
                    <div class="answer" v-if="scoreData.answerDisplay==1">
                        <span class="answerLeft">正确答案：</span>
                        <span class="answerRight" v-for="(ansval, index) in item.isAnswer" :key="index" :class="index==0?'marLeft':''">{{ansval}}</span>
                    </div>
                     <!-- answerDisplay：1 显示答案 -->
                    <div class="userAnswer" v-if="scoreData.answerDisplay==1">
                        <span class="leftbox">您的答案：
                            <span class="leftAns" v-for="(lival, likey) in item.choose" :key="likey">{{lival}}</span>
                          {{item.isRight==0?'回答错误':'回答正确'}}</span>
                          <!-- isRight：1 正确   scoreData.questionScore 1 显示分值 -->
                        <span class="rightBox" v-if="item.isRight==1&&scoreData.questionScore==1">+{{item.score}}</span>
                    </div>
                </div>
                <div class="line"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import { getAnswerDetail } from '@/api/questions'
    import { Dialog } from 'vant';
    export default {
        data() {
            return {
                active: 0,
                chelkAtive: '',
                message: '',
                scoreData: {}
            }
        },
        computed: {},
        filters: {
            sliceMsg(val) {
                let newVal = val + 1
                return newVal < 10 ? '0' + newVal : newVal;
            }
        },
        created() {
            this.getData()
        },
        methods: {
            isChosen(choose,key) {
                return choose.includes(key);
            },
            showType(type) {
                let typeMap = {1: '单选题',2: '多选题',3: '单项填空',4: '问答题'}
                return typeMap[type]
            },
            async getData() {
                try {
                    const { code, data } = await getAnswerDetail({ id: this.$route.query.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid, answerId: this.$route.query.answerId })
                    if (code == 200) {
                        if (data.code === 0) {
                            this.scoreData = data.data
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {}
            },
            tagBtn(index) {
                this.chelkAtive = index
            },
            getClassNames(index) {
                let classNames = [];
                if (index === 0) {
                    classNames.push('tagCheck');
                }
                if (index === 2) {
                    classNames.push('checkErr');
                }
                // 其他逻辑
                return classNames;
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-tabs__nav {
        background: #F7F7F7 !important;
    }
    /deep/ .van-tabs__line {
        width: 28px;
        height: 2px;
        background: #B80000;
        border-radius: 1px;
    }
    /deep/ .van-tab__text--ellipsis {
        font-size: 17px;
    }
    .vanField {
        background: #F1F1F1;
        border-radius: 4px;
    }
    .detailpage {
        .score {
            padding: 0px 20px;
            width: 100%;
            height: 51px;
            display: inline-flex;
            justify-content: space-between;
            line-height: 51px;
            .scoreLeft {
                .name {
                    font-size: 16px;
                    font-weight: 500;
                    color: #323334;
                }
                .tips {
                    font-size: 14px;
                    font-weight: 400;
                    color: #7F7F7F;
                }
            }
            .scoreright {
                font-size: 16px;
                font-weight: 500;
                color: #E04733;
            }
        }
        .line {
            width: 100%;
            height: 5px;
            background: #F7F7F7;
        }
        .content {
            padding: 20px 22px;
            .topicType {
                font-size: 16px;
                font-weight: 400;
                color: #FF9812;
                line-height: 19px;
            }
            .topicCon {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .subscript {
                    background: #D8D8D8;
                    border-radius: 3px;
                    height: 19px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px 2px;
                    margin-top: 3px;
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .quest {
                    margin-left: 5px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                }
            }
            .questLine {
                margin-top: 10px;
                width: 100%;
                height: 1px;
                border: 1px solid #EDEDED;
            }
            .questImg {
                width: 100%;
                margin-top: 10px;
                display: flex;
                justify-content: center;
                img {
                    width: 135px;
                    height: 77px;
                }
            }
            .questList {
                margin-top: 12px;
                .tagCheck {
                    background: linear-gradient(133deg, #FFE159 0%, #FFAD46 100%) !important;
                }
                .checkErr {
                    background: linear-gradient(270deg, #DD3E2D 0%, #E86345 100%) !important;
                }
                .liSelect {
                    width: 100%;
                    height: 40px;
                    background: #F1F1F1;
                    border-radius: 23px;
                    font-size: 17px;
                    font-weight: 400;
                    color: #262729;
                    line-height: 40px;
                    margin-top: 12px;
                    padding: 0px 23px;
                    text-align: left;
                }
            }
            .questInput {
                margin-top: 15px;
            }
            .answer {
                margin-top: 15px;
                .answerLeft {
                    font-size: 14px;
                    font-weight: 400;
                    color: #7F7F7F;
                }
                .answerRight {
                    font-size: 14px;
                    font-weight: 500;
                    color: #FF9812;
                }
                .marLeft {
                  margin-left: 3px;
                }
            }
            .userAnswer {
                display: flex;
                justify-content: space-between;
                .leftbox {
                    font-size: 14px;
                    font-weight: 400;
                    color: #7F7F7F;
                    /* .leftAns{
                        margin-left: -4px;
                    } */
                }
                .rightBox {
                    font-size: 16px;
                    font-weight: 500;
                    color: #E04733;
                }
            }
        }
    }
</style>