<template>
    <div class="answerPage">
        <page-load v-if="pageLoading" />
        <div v-else class="page">
            <div class="topBox">
                <div class="Previous">
                    <img src="../../../assets/images/questions/answerIcon.png" alt="">
                    <span>共{{questList.length}}题</span>
                </div>
                <div class="Next ">
                    <span>当前第{{currentIndex+1}}题</span>
                </div>
            </div>
            <div class="line"></div>
            <div class="centerBox">
                <div class="countdown">
                    <countDown :originCountDown="duration" @timeCease="postSubmit"></countDown>
                </div>
                <div class="questType">{{questType}}</div>
                <div class="questName">{{current.question}}</div>
                <div class="questImg" v-if="current.questionImage!=''">
                    <img :src="current.questionImage" alt="">
                </div>
                <div class="questList" v-if="current.type===1||current.type===2">
                    <div v-for="(item,index) of current.option" :key="index">
                        <!-- 单选题 -->
                        <div class="liSelect" v-if="current.type===1" :class="chelkAtive==index?'tagCheck':''"
                            @click="tagBtn(index)">{{item}}</div>
                        <!-- 多选题 -->
                        <div class="liSelect" v-if="current.type===2"
                            :class="chelkAtiveArr.includes(index) ? 'tagCheck' : ''" @click="tagBtnArr(index)">
                            {{item}}
                        </div>
                    </div>
                </div>
                <!-- 单项填空 -->
                <div class="questList" v-if="current.type===3">
                    <van-field v-model="currentMessage" rows="2" autosize type="textarea" placeholder="请输入您的回答"
                        show-word-limit class="vanField" />
                </div>
                <!-- 问答 -->
                <div class="questList" v-if="current.type===4">
                    <van-field v-model="currentMessage" rows="2" autosize type="textarea" placeholder="请输入您的回答"
                        show-word-limit class="vanField" />
                </div>
            </div>
            <div class="submitBtn">
                <div class="leftbtn" v-if="currentIndex!=0" @click="lastTopic">上一题</div>
                <div class="rightbtn" v-if="currentIndex!=questList.length-1" :class="currentIndex!=0?'rightbtnLeft':''"
                    @click="nextTopic">下一题</div>
                <div class="rightbtn" v-if="currentIndex===questList.length-1"
                    :class="currentIndex!=0?'rightbtnLeft':''" @click="submit">提交答案</div>
            </div>
            <!-- 提交load -->
            <van-overlay :show="overlayShow" @click="overlayShow = false">
                <div class="wrapper" @click.stop>
                    <div class="block">
                        <van-loading size="24px" vertical color="#1989fa">提交中...</van-loading>
                    </div>
                </div>
            </van-overlay>
        </div>
    </div>
</template>
<script>
    import countDown from '../components/countdown'
    import { postAnswer } from '@/api/questions'
    import pageLoad from '../components/pageLoad'
    import { Dialog } from 'vant';
    import throttle from '../utils/throttle';
    export default {
        components: {
            countDown,
            pageLoad
        },
        data() {
            return {
                overlayShow: false,
                chelkAtive: '',
                chelkAtiveArr: [],
                currentRate: 87,
                questList: [],
                current: {},
                currentIndex: 0,
                currentMessage: '',
                pageLoading: false,
                questData: {},
                postQuestion: [],//提交数据
            }
        },
        created() {
            this.questData = this.$route.query;
            this.questList = this.$route.query.question;
            this.current = this.questList[this.currentIndex];
            this.pageLoading = true
            setTimeout(() => {
                this.pageLoading = false;
            }, 1000)
        },
        computed: {
            duration() {
                // 将字符串数字转换为整数类型
                return parseInt(this.questData.duration);
            },
            //题目类型
            questType() {
                let typeMap = { 1: '单选题', 2: '多选题', 3: '单项填空', 4: '问答题' }
                return typeMap[this.current.type]
            }
        },
        methods: {
            tagBtn(index) {
                this.chelkAtive = index
            },
            tagBtnArr(index) {
                if (this.chelkAtiveArr.includes(index)) {
                    // 如果该选项已被选中，则从选中数组中删除该选项
                    this.chelkAtiveArr.splice(this.chelkAtiveArr.indexOf(index), 1);
                } else {
                    // 如果该选项未被选中，则将其添加到选中数组中
                    this.chelkAtiveArr.push(index);
                }
            },
            //上一题
            lastTopic() {
                this.currentIndex--
                this.current = this.questList[this.currentIndex]
                let newArray = this.postQuestion.filter(item => item.id == this.current.id);//过滤数组中id等于this.current.id的元素
                this.chelkAtive = this.current.type === 1 ? newArray[0].choose[0] : '';
                this.chelkAtiveArr = this.current.type === 2 ? newArray[0].choose : [];
                this.currentMessage = (this.current.type === 3 || this.current.type === 4) ? newArray[0].text : '';
            },
            //下一题
            nextTopic() {
                this.handleQuestion();
                this.currentMessage = '';
                this.currentIndex++
                this.current = this.questList[this.currentIndex]
                //判断是否存在数据，如果存在数据则显示之前的选项
                let newArray = this.postQuestion.filter(item => item.id == this.current.id);//过滤数组中id等于this.current.id的元素
                this.chelkAtive = this.current.type === 1 && newArray.length ? newArray[0].choose[0] : '';
                this.chelkAtiveArr = this.current.type === 2 && newArray.length ? newArray[0].choose : [];
                this.currentMessage = (this.current.type === 3 || this.current.type === 4) && newArray.length ? newArray[0].text : '';
            },
            //提交
            submit: throttle(async function () {
                //提交的时候把最后一条数据也添加上
                //倒计时提交的时候判断提交数组this.postQuestion跟this.questData.question比较
                //假设this.questData.question中不存在this.postQuestion中相同的id数据，
                //则把this.questData.question按格式 let json = { id: this.questData.question[xx].id, choose: [], text: '' }; push到this.postQuestion数组中
                this.overlayShow = true
                let newQuestions = this.questData.question.filter((question) => {
                    return !this.postQuestion.some((postQuestion) => postQuestion.id === question.id);
                });
                if (newQuestions.length != 0) {
                    newQuestions.forEach(ele => {
                        let json = { id: ele.id, choose: [], text: '' };
                        this.postQuestion.push(json)
                    });
                }
                this.handleQuestion();
                try {
                    const { code, data, msg } = await postAnswer({ id: this.$route.query.id, unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid, question: this.postQuestion });
                    if (code === 200) {
                        if (data.code === 0) {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/questions/achievement?id=' + this.$route.query.id,
                                    query: {
                                        scoreData:JSON.stringify(data.data)
                                    },
                                });
                            }, 2000)
                        } else if (data.code === 5555) {
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) { } finally {
                    setTimeout(() => {
                        this.overlayShow = false
                    }, 2000)
                }
            }, 1500),
            //公共方法，将数据处理好格式
            handleQuestion() {
                let chooseMap = { 1: [this.chelkAtive], 2: this.chelkAtiveArr };
                let json = { id: this.current.id, choose: chooseMap[this.current.type] || [], text: this.currentMessage };
                let index = this.postQuestion.findIndex(item => item.id === json.id);
                if (index >= 0) {
                    this.postQuestion.splice(index, 1, json);
                } else {
                    this.postQuestion.push(json);
                }
            },
            //时间到期，自动提交
            postSubmit() {
                Dialog.alert({ message: '已到答题规定时间，系统自动提交答题！', confirmButtonColor: '#267EF0' });
                this.submit()
            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .van-field__control {
        font-size: 14px;
        font-weight: 400;
        color: #7F7F7F;
    }
    body {
        height: 100%;
    }
    .answerPage{
        width: 100%;
        height: auto;
        min-height: 100%;
        background-image: url("../../../assets/images/questions/answerBack.png");
        background-size: 100% 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        padding: 0px 35px;
        .topBox {
            position: absolute;
            left: 0px;
            top: 16px;
            width: 100%;
            display: flex;
            flex-direction: row;
            .Previous {
                width: 50%;
                height: 42px;
                background: linear-gradient(90deg, #F66152 0%, #FFC93E 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                transform: skew(-20deg, 0deg);
                transform-origin: 100% 0;
                img {
                    width: 14px;
                    height: 15px;
                }
                span {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
            .Next {
                width: 50%;
                height: 42px;
                background: linear-gradient(265deg, #F66152 0%, #FFC93E 100%);
                display: flex;
                justify-content: center;
                align-items: center;
                transform: skew(-20deg, 0deg);
                transform-origin: bottom left;
                margin-left: 16px;
                span {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }
        }
        .line {
            width: 100%;
            height: 20px;
        }
        .centerBox {
            width: 100%;
            padding: 0px 34px 50px 34px;
            background: #FFFFFF;
            border-radius: 17px;
            margin-top: 90px;
            .countdown {
                width: 64px;
                height: 64px;
                background: #FFFFFF;
                box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.18);
                border-radius: 50%;
                margin: 0 auto;
                position: relative;
                top: -30px;
                display: flex;
                justify-content: center;
                align-items: center;
                .circle {
                    width: 58px;
                    height: 58px;
                    margin: 0 auto;
                }
            }
            .questType {
                background: #FFEBE8;
                border-radius: 12px;
                width: 76px;
                text-align: center;
                padding: 2px 0px;
                font-size: 16px;
                font-weight: 400;
                color: #3F4045;
            }
            .questName {
                margin-top: 25px;
                font-size: 17px;
                font-weight: 400;
                color: #000000;
            }
            .questImg {
                margin-top: 10px;
                width: 100%;
                display: flex;
                justify-content: center;
                img {
                    width: 135px;
                    height: 77px;
                    background: #D8D8D8;
                    border-radius: 2px;
                }
            }
            .questList {
                margin-top: 28px;
                .tagCheck {
                    background: linear-gradient(133deg, #FFE159 0%, #FFAD46 100%) !important;
                }
                .liSelect {
                    width: 100%;
                    height: 40px;
                    background: #F1F1F1;
                    border-radius: 23px;
                    font-size: 17px;
                    font-weight: 400;
                    color: #262729;
                    text-align: center;
                    line-height: 40px;
                    margin-top: 12px;
                }
                .vanField {
                    background: #F1F1F1;
                    border-radius: 8px;
                }
            }
        }
        .submitBtn {
            display: inline-flex;
            margin-top: 35px;
            justify-content: center;
            width: 100%;
            .leftbtn {
                width: 50%;
                height: 42px;
                border-radius: 22px;
                font-size: 20px;
                font-weight: 500;
                color: #FFCA51;
                line-height: 42px;
                letter-spacing: 1px;
                text-align: center;
                background: rgba(255, 255, 255, 0.28);
                border-radius: 22px;
            }
            .rightbtn {
                width: 50%;
                height: 42px;
                border-radius: 22px;
                font-size: 20px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 42px;
                letter-spacing: 1px;
                text-align: center;
                background: linear-gradient(133deg, #FFE159 0%, #FFAD46 100%);
                border-radius: 22px;
            }
            .rightbtnLeft {
                margin-left: 20px;
            }
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .block {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom:200px;
    }
</style>