<template>
  <div class="index">
    <div class="tab" v-if="billData.list&&billData.list.length!=0">
      <div class="m16" v-for="(item, index) in billData.list" :key="index">
        <div class="tabTitle" :class="{ active: tabIndex == index }" @click="changeTab(index)">
          账单{{index+1}}
        </div>
      </div>
    </div>
    <div class="gray" v-if="billData.list&&billData.list.length!=0"></div>
    <van-empty  description="暂无内容" v-if="billData.list&&billData.list.length==0" />
    <div class="banxin" v-else>
      <div class="content">
        <div class="info" style="margin-top: 6px">
          <div class="left">归属网点</div>
          <div class="right">{{billCurrntData.branches||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">客户经理</div>
          <div class="right">{{billCurrntData.employee_name||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">客户姓名</div>
          <div class="right">{{billCurrntData.name||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">客户手机号</div>
          <div class="right">{{billCurrntData.phone||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">客户状态</div>
          <div class="right">{{billCurrntData.contactStatus|stutasType}}</div>
        </div>
        <div class="info">
          <div class="left">贷款起始时间</div>
          <div class="right">{{billCurrntData.startTime||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">剩余本金</div>
          <div class="right">{{billCurrntData.remainMoney||'-'}}</div>
        </div>
        <div class="info">
          <div class="left">贷款到期日期</div>
          <div class="right">{{billCurrntData.endTime||'-'}}</div>
        </div>
        <div class="info" v-if="billData.corp_data.loanUrl&&billData.corp_data.loanUrl!=''">
          <div class="left">贷款详情</div>
          <div class="right"><a :href="billData.corp_data.loanUrl">点击查看贷款详情</a></div>
        </div>
      </div>
      <div class="tips">
        贷款到期前，请您要做好贷款结清计划，以免因征信逾期对您造成不良影响。
      </div>
      <div class="company">{{billData.corp_data?billData.corp_data.name:''}}</div>
      <div class="time">{{billCurrntData.sendTime}}</div>
    </div>
  </div>
</template>
<script>
  import { openUserInfoApi, getContactLoan } from '@/api/loan'
  export default {
    data() {
      return {
        tabIndex: 0,
        billData: {},
        billCurrntData: {},
        id: '',
        employeeId: '',
        unionid: "",
        sopId:'',
      };
    },
    filters: {
      stutasType(value) {
        let type = ['正常用信', '已结清', '逾期']
        return type[value-1]
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.sopId= this.$route.query.sop_id;
      this.getOpenUserInfo()   
    },
    methods: {
      //用户登录授权
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          corp_id: that.id,
        }).then((res) => {
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/loan?corp_id=" +
              that.id +
              "&target=" +
              encodeURIComponent(
                "/billDetail/index" + location.search
              );
            window.location.href = redirectUrl;
            return;
          }
          this.unionid = res.data.unionid;
          this.getData();
        });
      },
      //获取账单信息
      getData() {
        let params = {
          union_id: this.unionid,
          corp_id: this.id,
          sop_id:this.sopId
        }
        getContactLoan(params).then((res) => {
          if (res.code == 200) {
            this.billData = res.data
            this.billCurrntData=res.data.list.length==0?{}:res.data.list[0]
          }
        })
      },
      changeTab(index) {
        this.tabIndex = index;
        this.billCurrntData= this.billData.list[index]
      },
    },
  };
</script>
<style lang="less" scoped>
  .tab {
    width: 100%;
    white-space: nowrap;
    height: 50px;
    line-height: 47px;
    background-color: #fff;
    overflow-x: auto;

    .m16 {
      display: inline-block;
      width: 25%;
      text-align: center;

      .tabTitle {
        margin: 0 auto;
        font-size: 14px;
        color: #4a4a4a;
        width: 37px;
      }

      .active {
        font-weight: bold;
        color: #3974c6;
        border-bottom: 3px solid #3975c6;
      }
    }
  }

  .gray {
    height: 8px;
    background: #f2f3f7;
  }

  .banxin {
    width: 91%;
    margin: 0 auto;
  }

  .content {
    font-size: 13px;
    color: #515151;
    overflow: auto;
    /* height: 250px; */
    background: #f2f3f7;
    border-radius: 4px;
    margin: 15px 0;

    .info {
      display: flex;
      height: 30px;
      line-height: 28px;
    }

    .left {
      width: 104px;
      margin-left: 20px;
    }
  }

  .tips {
    font-size: 13px;
    color: #515151;
    line-height: 19px;
  }

  .company,
  .time {
    width: 100%;
    text-align: right;
    font-size: 13px;
    color: #515151;
    line-height: 19px;
  }

  .company {
    margin-top: 22px;
  }
</style>