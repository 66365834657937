import {request,request_op} from "../plugins/axios";

//获取用户信息
export function getUserInfo(params) {
    return request_op({
        url: '/openUserInfo/red',
        method: 'get',
        params: params
    })
}
//获取红包信息
export function GetRedInfo(params) {
    return request({
        url: '/red/GetRedInfo',
        method: 'get',
        params: params
    })
}
//获取授权URl 
export function getredauthurl(params) {
    return request_op({
        url: '/openUserInfo/getredauthurl',
        method: 'get',
        params: params
    })
}
//领取红包
export function getReceivered(params) {
    return request_op({
        url: '/openUserInfo/receivered',
        method: 'post',
        data: params
    })
}