<template>
    <div class="loadBox">
        <!-- <van-overlay :show="show"> -->
        <van-loading size="24px" color="#0094ff" vertical class="load">加载数据中...</van-loading>
        <!-- </van-overlay> -->
    </div>
</template>
<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
<style scoped>
        body {
        height: 100%;
    }
    .loadBox {
        width: 100%;
        height: 100vh;
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .load {
        /* margin-top: 200px; */
    }
</style>