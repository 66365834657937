<template>
  <div class="all">
    <div class="pic">
        <img src="../../assets/images/succuss.png" alt="" />
    </div>
    <div class="info">信息安全认证成功！</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.all{
    height: 100%;
    overflow: hidden;
}
.pic{
    margin: 124px 0 28px 0;
    text-align: center;
}
img{   
    width: 89px;
    height: 64px;
}
.info {
  height: 25px;
  font-size: 18px;
  font-weight: bold;
  color: #323232;
  text-align: center;
}
</style>