<template>
    <div class="page">
        <div class="cover">
            <img class="logo" :src="coverInfo.corp_logo" />
            <div class="title">{{coverInfo.corp_name}}</div>
            <div class="info">
                <span class="info_content">{{coverInfo.red_describe}}</span>
            </div>
            <div class="imgbox">
                <img class="btn" :class="animation?'anim':''" src="../../static/envelope/btn.png" @click="openBtn" />
            </div>

        </div>
    </div>
</template>
<script>
    import { getUserInfo, GetRedInfo, getredauthurl, getReceivered } from '@/api/redEnvelope'
    export default {
        data() {
            return {
                animation: false,
                id: '',//跳转id
                weChatUserInfo: {},//用户信息,
                coverInfo: {},//红包信息
            }
        },
        created() {
            this.id = this.$route.query.id;
            //判断是否存在openid，如果存在openid，则表示用户已经打开红包授权回来，此时走领取红包流程
            this.$route.query.openid ? this.receivered(this.$route.query.openid) : ''
            this.getInfo()
        },
        methods: {
            //获取用户信息
            getInfo() {
                let that = this;
                getUserInfo({ id: this.id }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.openid === undefined) {
                            let redirectUrl = location.protocol + "//" + location.host + "/auth/red?id=" + that.id + "&target=" + encodeURIComponent("/redEnvelope/index" + location.search);
                            window.location.href = redirectUrl;
                            return
                        }
                        this.weChatUserInfo = res.data
                        this.getEnveInfo()
                    }
                })
            },
            //获取红包信息
            getEnveInfo() {
                GetRedInfo({ id: this.id }).then((res) => {
                    if (res.code == 200) {
                        this.coverInfo = res.data
                    }
                })
            },
            openBtn() {
                this.animation = true
                setTimeout(() => {
                    this.animation = false
                }, 1500)
                //获取授权url
                let params = {
                    id: this.id,
                    path: '/redEnvelope/index'
                }
                getredauthurl(params).then((res) => {
                    if (res.code == 200) {
                        if (res.data.status == 1) {
                            //状态 1=待领取 这个状态直接跳转页面授权获取openid
                            window.location.href = res.data.auth_url;
                        } else if (res.data.status == 2) {
                            // 2=已领取 代表红包已领取了，提示已领取就好
                            // this.$toast("红包已被领取！");
                            this.coverInfo.money = res.data.money;//显示已领取金额
                            this.$router.push({
                                path: '/redEnvelope/detail',
                                query: {
                                    coverInfo:this.coverInfo,
                                    id:this.id
                                }
                            })
                        } else if (res.data.status == 3) {
                            // 3=已有openid 代表红包未领取，通过返回的openid去领取代金券就好，不用再去跳转授权
                            this.receivered(res.data.openid)
                        }
                    }
                })

            },
            //领取红包
            receivered(openid) {
                let params = {
                    id: this.id,
                    openid: openid
                }
                getReceivered(params).then((res) => {
                    if (res.code == 200) {
                        // money表示多人红包显示金额
                        res.data.money ? this.coverInfo.money = res.data.money : ''
                        setTimeout(()=>{
                            this.$router.push({
                            path: '/redEnvelope/detail',
                            query: {
                                coverInfo:this.coverInfo,
                                id:this.id
                            }
                        })
                        },500)
                    }
                })
            }

        }
    }
</script>
<style lang="less" scoped>
    .page {
        width: 100%;
        height: 100vh;
        background-image: url("../../static/envelope/back.png");
        background-size: 100% 100%;

        .cover {
            width: 100%;
            height: 511px;
            background-image: url("../../static/envelope/cover.png");
            background-size: 100% 511px;
            position: relative;
            top: 100px;
            display: inline-block;

            .logo {
                margin-top: 50px;
                width: 69px;
                height: 69px;
                border-radius: 8px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }

            .title {
                font-size: 21px;
                font-weight: bold;
                color: #FCD47D;
                text-align: center;
                margin-top: 17px;
            }

            .info {
                margin-top: 45px;
                width: 200px;
                height: 31px;
                border-radius: 18px;
                background-color: rgba(0, 0, 0, 0.1);
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                justify-content: center;
                align-items: center;

                .info_content {
                    font-size: 15px;
                    font-weight: 400;
                    color: #FCD47D;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0 19px;
                    line-height: 31px;
                }
            }

            .imgbox {
                width: 100%;
                display: flex;
                justify-content: center;

                .btn {
                    margin-top: 50px;
                    width: 140px;
                    height: 140px;

                }

            }

        }

        .anim {
            -webkit-animation: proRotate 1s ease-in-out 500ms alternate none 1;
            animation: proRotate 1s ease-in-out 500ms alternate none 1;
        }

        @-webkit-keyframes proRotate {
            0% {
                -webkit-transform: perspective(400px) rotateY(0deg);
            }

            100% {
                -webkit-transform: perspective(400px) rotateY(180deg);
            }
        }

        @keyframes proRotate {
            0% {
                -webkit-transform: perspective(400px) rotateY(0deg);
            }

            100% {
                -webkit-transform: perspective(400px) rotateY(180deg);
            }
        }
    }
</style>