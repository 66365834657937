<template>
    <div class="pagePop">
        <!-- 1：客服二维码 2：兑换码 3：微信立减金 4：现场核销 5：现金红包 -->
        <!-- 客服二维码 -->
        <div class="styleBox1" v-if="showType==1">
            <div class="Qrcode">
                <img :src="prizeInfo.serviceQrCode" alt="">
            </div>
            <div class="tips">恭喜您中奖啦~，请添加客服二维码领取奖品哦！</div>
        </div>
        <!-- 兑换码 -->
        <div class="styleBox2" v-if="showType==2">
            <div class="title">兑换码</div>
            <div class="redeCode">{{prizeInfo.exchangeCode}}</div>
            <div class="tips">恭喜您中奖啦~，请牢记兑换码，可以把页面截图保存哦！</div>
        </div>
        <!-- 立减金 -->
        <div class="styleBox3" v-if="showType==3">
            <div class="backBox">
                <div class="Box3Amount">{{prizeInfo.name}}</div>
                <div class="Box3tips">恭喜您中奖啦！请稍后前往微信查看～</div>
                <img src="../../../assets/images/questions/cashBtn.png" alt="" @click="closeFun">
            </div>
        </div>
        <!-- 现场核销 -->
        <div class="styleBox4" v-if="showType==4">
            <div class="Qrcode" ref="qrcodeBox">
            </div>
            <div class="tips">恭喜您中奖啦~，持码到银行核销领取奖品！ 可以先把页面截图保存哦！</div>
        </div>
          <!-- 现金红包 -->
        <div class="styleBox5" v-if="showType==5">
            <div class="cashInfo">
                <div class="Box1Amount">{{prizeInfo.amount}}<span>元</span></div>
                <img src="../../../assets/images/questions/cashBtn.png" alt="" @click="closeFun">
            </div>
        </div>
        <div class="closeImg">
            <img src="../../../assets/images/questions/close.png" alt="" @click="closeFun">
        </div>
    </div>
</template>
<script>
    import QRCode from 'qrcodejs2'
    export default {
        data() {
            return {
                prizeInfo: {}
            }
        },
        props: {
            showType: {
                type: Number,
                default: 1
            },
        },
        methods: {
            closeFun() {
                this.$emit('close', '');
            },
            prizeInfoFun(data) {
                this.$refs.qrcodeBox?this.$refs.qrcodeBox.innerHTML = "":''
                if (data.cashPrizeType == 4) {
                    setTimeout(() => {
                        this.crateCode(data)
                    }, 500);
                }
                this.prizeInfo = data
            },
            crateCode(data) {
                new QRCode(this.$refs.qrcodeBox, {
                    text: data.exchangeCode,
                    width: 150,
                    height: 150,
                    colorLight: '#fff',//二维码背景颜色
                    correctLevel: QRCode.CorrectLevel.L//容错率,L/M/H
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .pagePop {
        .styleBox5 {
            margin-top: 50px;
            width: 100%;
            height: 439px;
            background-image: url("../../../assets/images/questions/cachBack.png");
            background-size: 100% 100%;
            padding: 50px 26px;
            .cashInfo {
                width: 100%;
                height: 353px;
                background-image: url("../../../assets/images/questions/cashInfo.png");
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
                position: relative;
                .Box1Amount {
                    position: absolute;
                    top: 108px;
                    width: 100%;
                    text-align: center;
                    font-size: 40px;
                    font-weight: bold;
                    color: #C02C00;
                    margin-left: 10px;
                    padding: 0px 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    span {
                        font-size: 15px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #C02C00;
                    }
                }
                img {
                    position: absolute;
                    bottom: 30px;
                    width: 252px;
                    height: 54px;
                }
            }
        }
        .styleBox1 {
            margin-top: 100px;
            width: 100%;
            height: 402px;
            background-image: url("../../../assets/images/questions/codeBack.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title {
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
                color: #C05E32;
            }
            .redeCode {
                margin-top: 30px;
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
            }
            .Qrcode {
                img {
                    width: 150px;
                    height: 150px;
                    /* background: #FFFFFF; */
                    border-radius: 1px;
                }
            }
            .tips {
                width: 100%;
                padding: 30px 50px 0px 50px;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                color: #6C6C6C;
            }
        }
        .styleBox2 {
            margin-top: 100px;
            width: 100%;
            height: 402px;
            background-image: url("../../../assets/images/questions/codeBack.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title {
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
                color: #C05E32;
            }
            .redeCode {
                margin-top: 30px;
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
            }
            .Qrcode {
                img {
                    width: 150px;
                    height: 150px;
                    /* background: #FFFFFF; */
                    border-radius: 1px;
                }
            }
            .tips {
                width: 100%;
                padding: 30px 50px 0px 50px;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                color: #6C6C6C;
            }
        }
        .styleBox3 {
            margin-top: 100px;
            width: 100%;
            height: 412px;
            padding: 0px 23px;
            .backBox {
                width: 100%;
                height: 100%;
                background-image: url("../../../assets/images/questions/reduction.png");
                background-size: 100% 100%;
                padding-top: 90px;
                position: relative;
                display: flex;
                justify-content: center;
                position: relative;
                .title {
                    width: 100%;
                    text-align: center;
                    font-size: 25px;
                    font-weight: 500;
                    color: #C05E32;
                }
                .Box3Amount {
                    position: absolute;
                    top: 135px;
                    width: 100%;
                    text-align: center;
                    font-size: 51px;
                    font-weight: bold;
                    color: #C02C00;
                    span {
                        font-size: 15px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #C02C00;
                    }
                }
                .Box3tips {
                    margin-top: 140px;
                    width: 100%;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: #6C6C6C;
                }
                img {
                    position: absolute;
                    bottom: 60px;
                    width: 252px;
                    height: 54px;
                }
            }
        }
        .styleBox4 {
            margin-top: 100px;
            width: 100%;
            height: 402px;
            background-image: url("../../../assets/images/questions/codeBack.png");
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .title {
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
                color: #C05E32;
            }
            .redeCode {
                margin-top: 30px;
                width: 100%;
                text-align: center;
                font-size: 25px;
                font-weight: 500;
            }
            .Qrcode {
                img {
                    width: 150px;
                    height: 150px;
                    /* background: #FFFFFF; */
                    border-radius: 1px;
                }
            }
            .tips {
                width: 100%;
                padding: 30px 50px 0px 50px;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                color: #6C6C6C;
            }
        }
        .closeImg {
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            img {
                width: 32px;
                height: 32px;
            }
        }
    }
</style>