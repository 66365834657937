<template>
    <div class="page">
        <div class="centerBox">
            <div class="leftTop"></div>
            <div class="topBox">
                <div class="separate">
                    <div class="nq1">{{scoreData.questionNum}}道</div>
                    <div class="nqTips">共答题</div>
                </div>
                <div class="separate">
                    <div class="nq1">{{scoreData.useTime}}</div>
                    <div class="nqTips">耗时</div>
                </div>
            </div>
            <img class="imgIcon" v-if="scoreData.showGrades==0" src="../../assets/images/questions/icon.png" alt="">
            <div class="imgIconBack" v-if="scoreData.showGrades==1">
                <div class="fraction">{{scoreData.score}}</div>
            </div>
            <div class="shearBox">
                <div class="btn1" v-if="scoreData.certificateShare==1" @click="goPage('/questions/certificate')">分享我的证书
                </div>
                <div class="btn2" :class="scoreData.certificateShare==1?'maLeft':''"
                    @click="goPage('/questions/poster')">分享海报</div>
            </div>
            <div class="shearBox2" @click="guideShow=true">召唤小伙伴一起来填{{infoType}}</div>
            <div class="tips">本次剩余{{cashPrize==0? 1 : 0}}次领取机会</div>
        </div>
        <div class="detailBox">
            <div class="btn1" @click="godetailPage">查看{{infoType}}详情</div>
            <div class="btn2" @click="openPeizePage">去领奖</div>
        </div>
        <div class="Btnlink" @click="goPage('/questions/record?id='+id)">奖品记录</div>
        <!-- 奖品列表弹窗 -->
        <van-overlay :show="chieveShow">
            <prize-page @closeFun="chieveShow=false" @childSubmit="childSubmit" ref="prize"></prize-page>
        </van-overlay>
        <!-- 中奖弹窗 -->
        <van-overlay :show="childShow">
            <prize-pop @close="childShow=false" ref="prizePopVan" :showType="showType" />
        </van-overlay>
        <!-- 引导分享弹窗 -->
        <van-overlay :show="guideShow" @click="guideShow = false">
            <img class="guideImg" src="../../assets/images/questions/arrow.png" alt="">
            <div class="text">
                <div>请点击右上角</div>
                <div>选择发送给指定朋友</div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
    import prizePage from './components/prizePage'
    import prizePop from './components/prizePopup'
    import { getMyScores, getPreceivePrizes, getredauthurl, answerCashPrize } from '@/api/questions'
    import { Overlay, Dialog } from 'vant';
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {
            prizePage,
            prizePop
        },
        data() {
            return {
                id: '',
                show: false,
                guideShow: false,
                chieveShow: false,
                childShow: false,
                showType: 1,
                shareOption: {},
                scoreData: {},
                cashPrize: ''
            }
        },
        created() {
            this.id = this.$route.query.id
        },
        computed: {
            infoType() {
                let typeMap = { 1: '问答', 2: '问答', 3: '问卷' }
                return typeMap[this.scoreData.type]
            }
        },
        async mounted() {
            this.setShareInfo()//自定义分享信息
            //判断是否存在openid，如果存在openid，则表示用户已经授权回来
            if (this.$route.query.openid) {
                const openid = this.$route.query.openid//通过授权方式获取用户openid
                const unionid = JSON.parse(localStorage.getItem('userInfoFun')).unionid//通过授权方式获取用户unionid
                const openidMap = JSON.parse(localStorage.getItem('openidMap')) || {};
                openidMap[unionid] = openid;
                localStorage.setItem('openidMap', JSON.stringify(openidMap));
                this.$nextTick(async () => {
                    this.scoreData = JSON.parse(localStorage.getItem('achievementInfo'))
                    await this.getrCashPrize()//查询是否领奖
                    if (this.scoreData != null) {//防止返回事件再触发领奖
                        await this.receivered(this.$route.query.openid)
                    }
                    localStorage.removeItem("achievementInfo"); // 删除本地存储中的缓存数据
                })
            } else {
                this.scoreData = JSON.parse(this.$route.query.scoreData)
                this.getrCashPrize()//查询是否领奖
            }
        },
        beforeRouteLeave(to, from, next) {
            // 判断是否需要回退
            if (to.path === '/questionNaire/index') {
                next(false);// 阻止默认的路由跳转行为
                this.$router.go(-2); // 执行回退操作
                return;
            }
            // 继续路由跳转
            next();
        },
        methods: {
            //获取是否领取
            async getrCashPrize() {
                let params = {
                    id: this.$route.query.id,
                    unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid,
                    answerId: this.scoreData.answerId
                }
                const { code, data, msg } = await answerCashPrize(params)
                if (code === 200) {
                    if (data.code === 0) {
                        this.cashPrize = data.data.cashPrize//设置已领取状态
                    } else if (data.code === 5555) {
                    } else {
                        Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                    }
                }
            },
            goPage(path) {
                //授权下缓存当前信息
                this.$route.query.openid ? localStorage['achievementInfo'] = JSON.stringify(this.scoreData) : ""
                this.$router.push({
                    path: path,
                    query: '',
                });
            },
            // 分享
            shearFun() {
                let params = {
                    title: JSON.parse(localStorage.getItem('certificateInfo')).shareTitle, // 分享标题
                    desc: JSON.parse(localStorage.getItem('certificateInfo')).shareDescription, // 分享描述
                    link: location.protocol + "//" + location.host + '/questions/index?id=' + this.$route.query.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: JSON.parse(localStorage.getItem('certificateInfo')).shareImage, // 分享图标
                };
                wx.onMenuShareAppMessage(params);//分享到朋友
            },
            openPeizePage() {
                if (this.cashPrize == 1) {
                    Dialog.confirm({
                        message: '已经没有领奖次数啦~',
                        confirmButtonColor: '#267EF0',
                        confirmButtonText: '奖品记录'
                    }).then(() => {
                        this.goPage('/questions/record?id=' + this.id)
                    }).catch(() => {
                    });
                    return
                }
                this.$refs.prize.createPage();
                this.$refs.prize.getData(this.scoreData.score);//查询奖品  传递当前分数值
                this.chieveShow = true
            },
            godetailPage() {
                let pathMap = {
                    1: '/questions/detailPage', // 1：问答考试
                    2: '/questions/detailPage', // 2：开奖问答
                    3: '/questionsNaire/detailPage' // 3：问卷
                };
                let type = this.scoreData.type;
                //授权下缓存当前信息
                this.$route.query.openid ? localStorage['achievementInfo'] = JSON.stringify(this.scoreData) : ""
                this.$router.push({
                    path: pathMap[type],
                    query: {
                        id: this.$route.query.id,
                        answerId: this.scoreData.answerId
                    }
                });
            },
            async childSubmit(prizeInfo) {
                ///现金红包 立减金发放的openid调取getredauthurl接口先获取openid
                try {
                    let prizeDetail = prizeInfo.prizeDetail
                    ////现金红包 立减金发放的openid
                    if (prizeDetail.cashPrizeType == 3 || prizeDetail.cashPrizeType == 5) {
                        //判断是否存在指定的unionid缓存
                        const unionid = JSON.parse(localStorage.getItem('userInfoFun')).unionid//需要获取openid的用户unionid
                        const openidMap = JSON.parse(localStorage.getItem('openidMap')) || {};
                        if (unionid in openidMap) {
                            //如果存在，直接从本地存储中获取openid
                            const openid = openidMap[unionid];
                            //使用openid进行后续操作
                            let params = {
                                id: this.$route.query.id,//活动id
                                unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid,
                                prizeId: prizeDetail.id,//奖品id
                                openid: openid,
                                userAnswerId: this.scoreData.answerId,//答题id
                            }
                            this.preceiveFun(params)
                        } else {
                            const id = this.$route.query.id;
                            const prizeId = prizeDetail.id;
                            const redirectUrl = `/questions/achievement?id=${id}&prizeId=${prizeId}&scoreData=${this.scoreData}`;
                            localStorage['achievementInfo'] = JSON.stringify(this.scoreData)//缓存当前信息
                            localStorage['paizeInfo'] = JSON.stringify(prizeDetail) // //缓存领取信息
                            getredauthurl({ id: this.$route.query.id, path: redirectUrl }).then((res) => {
                                window.location.replace(res.data.auth_url);// 将当前历史记录替换为授权后的页面
                            })
                        }
                    } else {
                        let params = {
                            id: this.$route.query.id,
                            unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid,
                            prizeId: prizeDetail.id,
                            userAnswerId: this.scoreData.answerId,//答题id
                        }
                        this.preceiveFun(params, prizeDetail)
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            // 自定义分享
            setShareInfo() {
                let timestamp = new Date().getTime()
                this.shareOption = {
                    title: JSON.parse(localStorage.getItem('certificateInfo')).shareTitle, // 分享标题
                    desc: JSON.parse(localStorage.getItem('certificateInfo')).shareDescription, // 分享描述
                    link: location.protocol + "//" + location.host + '/questions/index?id=' + this.$route.query.id + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: JSON.parse(localStorage.getItem('certificateInfo')).shareImage, // 分享图标
                };
                let that = this;
                let shareFunction = function () {
                    wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
                    wx.updateAppMessageShareData(that.shareOption);//分享到朋友
                    wx.onMenuShareQQ(that.shareOption);//分享到qq
                    wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
                };
                //加载微信配置
                wxConfig(shareFunction);
            },
            //授权回调
            receivered(openid) {
                let params = {
                    id: this.$route.query.id,
                    unionId: JSON.parse(localStorage.getItem('userInfoFun')).unionid,
                    prizeId: this.$route.query.prizeId,
                    openid: openid,
                    userAnswerId: this.scoreData.answerId,//答题id
                }
                this.preceiveFun(params)
            },
            //领取接口
            async preceiveFun(params, prizeDetail) {
                try {
                    if (this.cashPrize === 0) {//状态为已领取的将不执行
                        const { code, data, msg } = await getPreceivePrizes(params)
                        if (code === 200) {
                            if (data.code === 0) {
                                //1：客服二维码 2：兑换码 3：微信立减金 4：现场核销 5：现金红包
                                if (prizeDetail == undefined) {//3 5需要openid
                                    this.showType = data.data.cashPrizeType;
                                    this.$refs.prizePopVan.prizeInfoFun(data.data);
                                } else {// 1 2 4
                                    prizeDetail.cashPrizeType != 1 ? this.$refs.prizePopVan.prizeInfoFun(data.data) : this.$refs.prizePopVan.prizeInfoFun(prizeDetail);
                                    this.showType = prizeDetail.cashPrizeType;
                                }
                                this.chieveShow = false;
                                this.childShow = true;
                                this.getrCashPrize()//查询是否领奖
                            } else if (data.code === 5555) {
                            } else {
                                Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                            }
                        }
                    }
                } catch (error) {
                    console.log('error', error)
                }
            }
        }
    }
</script>
<style lang="less" scoped>
    body {
        height: 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        /* 保证内容较少时也能铺满页面 */
        background-image: url("../../assets/images/questions/backImg.png");
        background-size: cover;
        padding: 50px 20px;
        .centerBox {
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 0px rgba(164, 164, 164, 0.2);
            border-radius: 21px;
            padding: 32px 15px;
            position: relative;
            .leftTop {
                position: absolute;
                left: 0;
                top: 0;
                width: 204px;
                height: 150px;
                background: linear-gradient(180deg, #FDDC5C 0%, #FBB62F 100%);
                opacity: 0.25;
                filter: blur(50px);
            }
            .topBox {
                display: flex;
                justify-content: space-between;
                .separate {
                    width: 30%;
                    text-align: center;
                    .nq1 {
                        font-size: 19px;
                        font-weight: bold;
                        color: #000000;
                    }
                    .nqTips {
                        font-size: 15px;
                        font-weight: 400;
                        color: #888888;
                    }
                }
            }
            .imgIcon {
                width: 217px;
                height: 183px;
                display: block;
                margin: 0 auto;
            }
            .imgIconBack {
                width: 217px;
                height: 183px;
                background-image: url("../../assets/images/questions/fraction.png");
                background-size: 100% 100%;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .fraction {
                    margin-top: 25px;
                    margin-left: 5px;
                    font-size: 36px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .shearBox {
                margin-top: 50px;
                width: 100%;
                display: inline-flex;
                font-size: 17px;
                font-weight: 400;
                color: #AA0000;
                text-align: center;
                .btn1 {
                    height: 37px;
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 21px;
                    border: 1px solid #AA0000;
                    line-height: 37px;
                }
                .btn2 {
                    height: 37px;
                    width: 100%;
                    background: linear-gradient(360deg, #F3C100 0%, #F3C204 12%, #FFEA7C 100%);
                    border-radius: 21px;
                    line-height: 37px;
                }
                .maLeft {
                    margin-left: 10px;
                }
            }
            .shearBox2 {
                width: 100%;
                height: 37px;
                line-height: 37px;
                background: linear-gradient(360deg, #F3C100 0%, #F3C204 12%, #FFEA7C 100%);
                border-radius: 21px;
                font-size: 17px;
                font-weight: 400;
                color: #AA0000;
                text-align: center;
                margin-top: 10px;
            }
            .tips {
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: #888888;
                margin-top: 38px;
            }
        }
        .detailBox {
            display: inline-flex;
            width: 100%;
            font-size: 17px;
            font-weight: 400;
            color: #AA0000;
            text-align: center;
            margin-top: 32px;
            .btn1 {
                height: 37px;
                width: 50%;
                background: #FFFFFF;
                border-radius: 21px;
                line-height: 37px;
            }
            .btn2 {
                margin-left: 10px;
                height: 37px;
                width: 50%;
                background: linear-gradient(360deg, #F3C100 0%, #F3C204 12%, #FFEA7C 100%);
                border-radius: 21px;
                line-height: 37px;
            }
        }
        .Btnlink {
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #888888;
            margin-top: 20px;
            text-decoration: underline;
        }
        .guideImg {
            width: 99px;
            height: 79px;
            position: absolute;
            top: 10px;
            right: 30px;
        }
        .text {
            position: absolute;
            top: 95px;
            right: 30px;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
            div {
                width: 200px;
                text-align: center;
            }
        }
    }
</style>