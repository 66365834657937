<template>
    <div class="page" :style="{backgroundColor: `${homePage.backgroundColor}`}">
        <div class="banBox" :style="{backgroundImage: 'url(' + homePage.banner + ')', backgroundSize:'cover'}">
            <div class="centerBtn" :style="{background: `${homePage.buttonBackgroundColor}`}" @click="goPage('/questions/myAcvin')">我的成绩</div>
        </div>
        <div class="bomBox">
            <div class="centerBox" :style="{backgroundColor: `${homePage.backgroundColor}`}">
                <div class="sideBox">
                    <div class="title">题目详情</div>
                    <div class="flexBox">
                        <div class="box">
                            <div class="numerical">{{ durationText }}</div>
                            <div>答题时长(秒)</div>
                        </div>
                        <div class="box">
                            <div class="numerical">{{homePage.questionNum}}题</div>
                            <div>答题数(量)</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="submitBtn">
                <div class="btn" :style="{background: `${homePage.buttonBackgroundColor}`}" @click="goQuest">{{homePage.homeButtonText}}</div>
            </div>
            <div class="ruleName">
                <div class="round6"></div>
                <div class="round9"></div>
                <span class="name">活动规则</span>
                <div class="round9"></div>
                <div class="round6"></div>
            </div>
            <div class="ruleText">
                <div>{{homePage.rule}}</div>
            </div>
            <div class="QrCode" v-if="homePage.groupEntryGuidance==1">
                <img :src="homePage.groupEntry" alt="">
            </div>
            <div class="herfLinkbox" v-if="homePage.groupEntryGuidance==2">
                <a :href="homePage.groupEntry" class="herfLink" >入群引导链接</a>
            </div>
            <van-overlay :show="cardShow">
                <customCard @close="cardShow=false" :qrcodeUrl="qrcodeUrl" :qrcodeName="qrcodeName"></customCard>
            </van-overlay>
        </div>
    </div>
</template>
<script>
    import { getUserInfo, getHomepageInfo, getRecord } from '@/api/questions'
    import { Dialog, Overlay } from 'vant';
    import customCard from './components/customCard'
    export default {
        components: {
            customCard
        },
        data() {
            return {
                id: '',
                weChatUserInfo: {},//微信用户信息
                homePage: {},
                cardShow: false,
                qrcodeUrl: '',
                qrcodeName: ''
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.getInfo()//获取用户信息
            // 以下为调试使用
            // let json={
            //     city: "",
            //     country: "",
            //     headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/FnNJ9flAic55ka49OhjjTc0azXIwQn4jUSRibk1OT5DeTpRdyCx7yNDVxeHrIVp06yJ898R8YqwnYUfpe1sWx6rA/132",
            //     language: "",
            //     nickname: "Jase",
            //     openid: "oWx9w6NbzSXxp6z9Rjy5eL057hBw",
            //     privilege: [],
            //     province: "",
            //     sex: 0,
            //     unionid: "o7W6Z5kjSUiRLremGAVuVS3IdKAA"
            // }
            //  this.weChatUserInfo=json
            // localStorage['userInfoFun'] = JSON.stringify(json)
            // this.getHomeInfo()
        },
        mounted() {
        },
        computed: {
            durationText() {
                // 计算持续时间的文本表示
                 if (!this.homePage.duration) {
                    return '无限制';
                } else {
                    return `${this.homePage.duration}秒`;
                }
            }
        },
        methods: {
            //获取用户信息
            getInfo() {
                let that = this;
                //如果为空数据  则重定向页面
                getUserInfo({ id: this.id }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.openid === undefined) {
                            let redirectUrl = location.protocol + "//" + location.host + "/auth/question?id=" + that.id + "&target=" + encodeURIComponent("/questions/index" + location.search);
                            window.location.href = redirectUrl;
                            return
                        }
                        this.weChatUserInfo = res.data
                        localStorage.setItem('userInfoFun', JSON.stringify(res.data));// 将数据存储到本地缓存
                        this.getHomeInfo()
                    }
                })
            },
            //获取首页信息
            getHomeInfo() {
                getHomepageInfo({ id: this.id, unionId: this.weChatUserInfo.unionid }).then((res) => {
                    if (res.code == 200) {
                        this.homePage = res.data
                        localStorage.setItem('certificateInfo', JSON.stringify(res.data));//设置证书信息缓存
                        this.setShareInfo()//自定义分享信息
                    }
                })
            },
            async goQuest() {
                try {
                    const { code, data, msg } = await getRecord({ id: this.id, unionId: this.weChatUserInfo.unionid });
                    let pathMap = {
                        1: '/questions/answer?id=' + this.id, // 1：问答考试
                        2: '/questions/answer?id=' + this.id, // 2：开奖问答
                        3: '/questionNaire/index?id=' + this.id // 3：问卷
                    };
                    let type = this.homePage.type;
                    if (code === 200) {
                        if (data.code === 0) {
                            this.$router.push({ path: pathMap[type], query: data.data });
                        } else if (data.code === 5555) {
                            this.qrcodeName = data.data.name;
                            this.qrcodeUrl = data.data.qrcode;
                            this.cardShow = true;
                        } else {
                            Dialog.alert({ message: data.msg, confirmButtonColor: '#267EF0' });
                        }
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            },
            goPage(path){
                this.$router.push({ path: path, query: {id:this.id,} });
            },
            // 自定义分享
            setShareInfo() {
                let timestamp = new Date().getTime()
                this.shareOption = {
                    title: JSON.parse(localStorage.getItem('certificateInfo')).shareTitle, // 分享标题
                    desc: JSON.parse(localStorage.getItem('certificateInfo')).shareDescription, // 分享描述
                    link: location.protocol + "//" + location.host + '/questions/index?id=' + this.$route.query.id+'&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: JSON.parse(localStorage.getItem('certificateInfo')).shareImage, // 分享图标
                };
                let that = this;
                let shareFunction = function () {
                    //需在用户可能点击分享按钮前就先调用
                    wx.onMenuShareTimeline(that.shareOption);//分享到朋友圈
                    wx.onMenuShareAppMessage(that.shareOption);//分享到朋友
                    wx.onMenuShareQQ(that.shareOption);//分享到qq
                    wx.onMenuShareQZone(that.shareOption);//分享到QQ空间
                };
                //加载微信配置
                wxConfig(shareFunction);
            },
        }
    }
</script>
<style lang="less" scoped>
        body {
        height: 100%;
    }
    .page {
        width: 100%;
        height: auto;
        min-height: 100%;
        .banBox {
            width: 100%;
            height: 377px;
            /* background-image: url("../../assets/images/questions/banner.png");
            background-size: cover; */
            position: relative;
            .centerBtn {
                position: absolute;
                right: 0;
                top: 30px;
                width: 82px;
                height: 28px;
                /* background: linear-gradient(180deg, #F9E781 0%, #F5CA1B 100%); */
                border-radius: 17px 0px 0px 17px;
                font-size: 15px;
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;
                line-height: 28px;
            }
        }
        .bomBox {
            position: relative;
            z-index: 199;
            width: 100%;
            height: calc(100% - 377px);
            /* height: auto; */
            /* background: linear-gradient(180deg, #61B8FF 0%, #61B8FF 30%, #2A4DFF 100%); */
            padding: 0 24px;
            text-align: center;
            .centerBox {
                position: relative;
                top: -60px;
                width: 100%;
                border-radius: 6px;
                .sideBox{
                    width: 100%;
                    height: 100%;
                    padding: 20px 33px;
                    border-radius: 6px;
                    background: rgba(255,255,255,0.24);
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: center;
                }
                .flexBox {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    .box {
                        width: 50%;
                        .numerical {
                            margin-top: 20px;
                            margin-bottom: 10px;
                        }
                        div {
                            text-align: center;
                            font-size: 17px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }
            }
            }
            .submitBtn {
                margin-top: -40px;
                width: 100%;
                height: 42px;
                background: rgba(255, 255, 255, 0.27);
                border-radius: 21px;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn {
                    width: 98%;
                    height: 37px;
                    /* background: linear-gradient(360deg, #F3C100 0%, #F3C204 12%, #FFEA7C 100%); */
                    border-radius: 21px;
                    font-size: 17px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 37px;
                    text-align: center;
                }
            }
            .ruleName {
                font-size: 17px;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 23px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 15px;
                .name {
                    margin: 0 7px;
                }
                .round6 {
                    width: 6px;
                    height: 6px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    margin: 0 4px;
                }
                .round9 {
                    width: 9px;
                    height: 9px;
                    background: #FFFFFF;
                    border-radius: 50%;
                }
            }
            .ruleText {
                margin-top: 14px;
                width: 100%;
                div {
                    font-size: 16px;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 22px;
                    word-break: break-all;
                    white-space: pre-wrap;
                }
            }
            .QrCode img {
                margin-top: 17px;
                width: 91px;
                height: 91px;
                margin-bottom: 12px;
            }
            .herfLinkbox{
                margin-top: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                .herfLink {
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
                text-decoration: underline;
            }
            }
        }
    }
    @media (max-height: 799px) {
        .page .bomBox {
            height: calc(100% - 377px) !important;
        }
    }
</style>