<template>
    <div class="page">
        <div class="map_box">
            <baidu-map class='bm-view' :center="center" :zoom="zoom" @ready="handler">
                <bm-marker :position="center" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
            </baidu-map>
        </div>
        <div class="content">
            <div class="head">
                <div class="head_left">
                    <span class="title">{{queryData.title}}</span>
                    <span class="tip">{{queryData.address}}</span>
                </div>
                <img src="../../../static/wowebsite/goloction.png" alt="">
            </div>
            <div class="line"></div>
            <div class="btn_box">
                <div class="btn">查看周边</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getbankShare } from '@/api/wowebsite'
    import { wxConfig } from "@/utils/wxCodeAuth";
    export default {
        components: {

        },
        data() {
            return {
                active: 2,
                list: [{
                    img: require('../../../static/wowebsite/wodeyuyue-2@2x.png'),
                    name: '我的预约'
                }, {
                    img: require('../../../static/wowebsite/navicon-yhxy@2x.png'),
                    name: '用户协议'
                }],
                figureData: [{}, {}, {}, {}, {}, {}, {}, {}],
                //BMap类
                BMap: null,
                //地图对象
                map: null,
                center: { lng: 114.029022, lat: 22.646191 },
                zoom: 3,
                queryData: {
                    title: '',
                    address: '-',
                    latitude: 22.646191,
                    longitude: 114.029022
                }
            }

        },
        created() {
            if (this.$route.query) {
                this.queryData = this.$route.query
                this.center.lng = this.$route.query.longitude
                this.center.lat = this.$route.query.latitude
            }
            this.shareData()
        },
        mounted() {
        },
        methods: {
             //获取分享内容
             shareData() {
                getbankShare({ b: sessionStorage['wowebsiteFun'] }).then((res) => {
                    if (res.code == 200) {
                        if (res.data.title) {
                            let timestamp = new Date().getTime()
                            //分享内容
                            let shareOption = {
                                title: res.data.title, // 分享标题
                                desc: res.data.summary, // 分享描述
                                link: res.data.shareUrl + '&t=' + timestamp, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                                imgUrl: res.data.image, // 分享图标
                            };
                            let shareFunction = function () {
                                //需在用户可能点击分享按钮前就先调用
                                wx.updateAppMessageShareData(shareOption);
                            };
                            //加载微信配置
                            wxConfig(shareFunction);
                        }
                    }
                })
            },
            handler({ BMap, map }) {
                console.log(BMap, map)
                this.center.lng = 114.029022
                this.center.lat = 22.646191
                this.zoom = 15
            },
            goNav() {
                this.$router.push({
                    path: "/wowebsite/navigation",
                    query: '',
                });
            },

        }
    }
</script>

<style lang="less" scoped>
    .page {
        background: #F9F9F9;
        // padding: 15px 12px 19px 12px;
        height: 100%;

        .map_box {
            width: 100%;
            height: 100%;
            background: #D8D8D8;
        }

        .content {
            position: fixed;
            bottom: 0;
            z-index: 999;
            width: 100%;
            background: #FFFFFF;
            padding: 12px;
            height: auto;
            margin-top: -20px;
            border-radius: 16px 16px 0px 0px;

            .head {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .head_left {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                        color: #343333;
                    }

                    .tip {
                        margin-top: 5px;
                        font-size: 12px;
                        font-weight: 400;
                        color: #969696;
                    }
                }

                img {
                    width: 47px;
                    height: 47px;
                }
            }

            .line {
                width: 100%;
                height: 1px;
                opacity: 0.37;
                background: #979797;
                margin-top: 10px;
            }

            .btn_box {
                margin: 0px 0 10px 0;

                .btn {
                    margin-top: 15px;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 400;
                    color: #969696;
                }
            }


        }
    }

    .bm-view {
        width: 100%;
        height: 100%;
    }
</style>